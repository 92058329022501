import type { ServiceLinkItem } from '@root/src/server/types';

import createLink from './create-link';

export default function createListItemReducer(
  acc: HTMLLIElement[],
  { name, hasAccess = false, ...rest }: ServiceLinkItem,
) {
  const listItem = document.createElement('li');
  listItem.appendChild(
    createLink({
      isSubscribed: hasAccess,
      text: name,
      ...rest,
    }),
  );

  return [...acc, listItem];
}
