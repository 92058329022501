import type { NavItem } from '@client/types';

// Assets: SVG
import ddArrowSvg from '../assets/svgs/dd-arrow.svg';

export default function createLink({
  hasChildren = false,
  isExternalService,
  isSubscribed = true,
  purchaseUrl,
  text,
  url,
  isActive,
}: NavItem & { hasChildren?: boolean }) {
  const anchorElm = document.createElement('a');

  anchorElm.setAttribute('href', url);
  if (isActive) anchorElm.classList.add('active');
  if (isExternalService) {
    anchorElm.setAttribute('target', '_blank');
    anchorElm.setAttribute('rel', 'noopener');
  }
  anchorElm.dataset.growthHubLink = undefined;

  anchorElm.innerHTML = `
    <button
      data-gh-service-name="${text}"
      data-gh-service-is-subscribed="${isSubscribed}"
      data-gh-service-buy-link="${purchaseUrl}"
    >${text}${
      hasChildren ? `<span class="dd-arrow">${ddArrowSvg}</span>` : ''
    }</button>
  `;

  return anchorElm;
}
