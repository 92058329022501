import { GrowthHubQueryParam } from '../types';

export default function parseGhQp(ghQpStr: string) {
  try {
    const ghQp = JSON.parse(ghQpStr) as GrowthHubQueryParam;

    return ghQp;
  } catch (err) {
    // TODO: Add better error logging logic
    // eslint-disable-next-line no-console
    console.error('gh-query-param string is not properly formatted JSON', err);
    throw err;
  }
}
