import {
  get as getCookie,
  set as setCookie,
  remove as removeCookie,
} from 'es-cookie';
import pkceChallenge from 'pkce-challenge';
import invariant from 'tiny-invariant';
import type {
  BBMemberships,
  BBUser,
  Service,
  ServiceLinkItem,
  TemplateVars,
} from '~/types';
import fetchRetry from 'fetch-retry';
import A11yDialog from 'a11y-dialog';
import { ActiveStateService } from './services/active-state-service';

type CurrentUser = {
  currentAccount: {
    id: BBUser['data']['current_account']['id'];
    name: BBUser['data']['current_account']['name'];
  };
  email: BBUser['data']['email'];
  fullName: BBUser['data']['fullname'];
  isSuper: BBUser['data']['is_super'];
  userHash: BBUser['data']['user_hash'];
  uuid: BBUser['data']['uuid'];
};

type Intercom = Intercom_.IntercomCommandSignature & Intercom_.IntercomStatic;

declare global {
  interface Window {
    XplGrowthHub: {
      currentUser: CurrentUser;
      init(): void;
      readonly isActive: boolean;
      setIsActive(): boolean;
    };
    attachEvent: (...args: Array<unknown>) => void;
    rebootIntercom: (options: Intercom_.IntercomSettings) => Intercom;
    startIntercomMessenger: (
      appId: Intercom_.IntercomSettings['app_id'],
      options: Intercom_.IntercomSettings,
    ) => Intercom;
  }
}

declare const APOLLO_CSS: string;
declare const GROWTH_HUB_CSS: string;
declare const GROWTH_HUB_MODAL_CSS: string;

interface GrowthHubQueryParam {
  code: string;
}

interface NavItem {
  isExternalService: boolean;
  isActive?: boolean;
  isSubscribed?: boolean;
  purchaseUrl?: string;
  text: string;
  url: string;
}

(function () {
  function parsePostMsg(msgData: string) {
    let parsedData;

    if (
      typeof msgData === 'string' &&
      msgData.includes('{') &&
      msgData.includes('}')
    ) {
      parsedData = JSON.parse(msgData) as TemplateVars['iframeMsg'];
    } else {
      parsedData = msgData;
    }

    return parsedData as TemplateVars['iframeMsg'];
  }

  function parseGhQp(ghQpStr: string) {
    try {
      const ghQp = JSON.parse(ghQpStr) as GrowthHubQueryParam;

      return ghQp;
    } catch (err) {
      // TODO: Add better error logging logic
      // eslint-disable-next-line no-console
      console.error(
        'gh-query-param string is not properly formatted JSON',
        err,
      );
      throw err;
    }
  }

  const generateAccountListReducer =
    (selectedAccountId: number | undefined, isSuperUser = false) =>
    (
      acc: string,
      {
        name: label,
        id,
        account_user_uuid: uuid,
      }: BBMemberships['memberships'][0],
    ) => `
      ${acc}
      <li${selectedAccountId === id ? ' class="selected"' : ''}>
        <button
          class="xpl-list-item"
          data-gh-account-option="true"
          data-gh-account-option-name="${label}"
          data-gh-account-option-uuid="${uuid}"
          data-gh-account-option-id="${id}"
        >
          ${label} ${isSuperUser ? `<small>(id: ${id})</small>` : ''}
        </button>
      </li>
  `;

  type XplEvtNames = (typeof xplEvtNames)[number];
  type XplEvts = {
    [Property in XplEvtNames]: `XplGH::${Property}`;
  };
  type XplEvt = XplEvts[keyof XplEvts];

  const xplEvtNames = [
    'afterAccountSelected',
    'afterAuthComplete',
    'afterLogoutComplete',
  ] as const;
  const xplEvts = xplEvtNames.reduce(
    (acc, evt) => ({ ...acc, [evt]: `XplGH::${evt}` }),
    {},
  ) as XplEvts;

  function triggerEvent<T = unknown>(
    eventName: XplEvt,
    opts: CustomEventInit<T> = {},
  ) {
    const evt = new CustomEvent(eventName, opts);
    document.dispatchEvent(evt);
  }

  const selectedAccountNameAttrName = 'selected-account-name';
  const selectedAccountIdAttrName = 'selected-account-id';
  const isFixedAttrName = 'is-fixed';

  class GrowthHubElement extends HTMLElement {
    AUTH_SESSION_NAME = '__xpl-gh-auth-session';

    AUTH_CODE_VERIFIER_COOKIE_NAME = 'xpl-auth-code-verifier';

    GROWTH_HUB_QUERY_PARAM_KEY = '__xpl_gh';

    INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

    accountDeetsContainerSelector = 'account-details';

    accountPickerId = 'account-picker';

    accounts: BBMemberships['memberships'];

    baseAuthUrl = process.env.AUTH_API_URL;

    brandbotApiUrl = process.env.BRANDBOT_API_URL;

    clientId = process.env.AUTH_CLIENT_ID;

    currentAccountDisplaySelector = 'xpl-gh-current-account-display';

    ddArrowSVG = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7364 4.81359C14.0879 5.16506 14.0879 5.73492 13.7364 6.08639L8.63641 11.1864C8.28494 11.5378 7.71508 11.5378 7.36362 11.1864L2.2636 6.08639C1.91213 5.73492 1.91213 5.16506 2.2636 4.81359C2.61507 4.46212 3.18493 4.46212 3.5364 4.81359L8.00001 9.27721L12.4637 4.81359C12.8151 4.46212 13.3849 4.46212 13.7364 4.81359Z" fill="#6A6D7D"/>
      </svg>
    `;

    ddOpenSelector = 'xpl-dropdown--open';

    dialog: A11yDialog | null = null;

    // These are id selectors
    dialogContentSelectors = {
      accountSwitcher: 'dialog__account-switcher',
      buyNow: 'dialog__buy-now',
      buyNowLink: 'dialog__buy-now-button',
    };

    dialogSearchFormSelector = 'xpl-gh-dialog-search';

    dialogSelector = 'account-selector';

    fetchRetry: ReturnType<typeof fetchRetry>;

    growthHubBaseUrl = process.env.GROWTH_HUB_BASE_URL;

    growthHubHomeUrl = window.location.origin;

    growthHubSVG = `
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="34" viewBox="0 0 150 34" fill="none">
        <path d="M53.559 11.3096H50.6093V24.8825H53.559V11.3096Z" fill="#F44E27"/>
        <path d="M73.3015 13.9509C71.8555 13.9509 70.7566 15.0499 70.4095 15.956V13.9509H67.4597V24.8825H70.4095V19.195C70.4095 17.537 70.9493 16.4958 72.7038 16.4958H75.2102V13.9509H73.3015Z" fill="#F44E27"/>
        <path d="M60.4998 13.7003C57.3379 13.7003 54.793 16.2645 54.793 19.4263C54.793 22.5882 57.3572 25.1524 60.4998 25.1524C63.6424 25.1524 66.2066 22.5882 66.2066 19.4263C66.2258 16.2645 63.6616 13.7003 60.4998 13.7003ZM60.4998 22.4918C58.8031 22.4918 57.415 21.1037 57.415 19.4071C57.415 17.7104 58.8031 16.3223 60.4998 16.3223C62.1964 16.3223 63.5845 17.7104 63.5845 19.4071C63.5845 21.1229 62.1964 22.4918 60.4998 22.4918Z" fill="#F44E27"/>
        <path d="M25.16 3.01929C18.9712 3.30848 13.2837 6.50891 9.83264 11.6566C7.51907 11.2517 5.14767 11.8301 3.29681 13.2568V14.645C8.94577 14.645 13.5151 19.2142 13.5343 24.8439V24.8632H14.9225C16.3492 23.0123 16.9468 20.6409 16.5227 18.3274C21.6704 14.8956 24.8708 9.20807 25.16 3.01929ZM15.4045 10.3263C15.4045 8.97672 16.5034 7.87777 17.853 7.87777C19.2026 7.87777 20.3015 8.97672 20.3015 10.3263C20.3015 11.6759 19.2026 12.7748 17.853 12.7748C16.5034 12.7748 15.4045 11.6759 15.4045 10.3263Z" fill="#F44E27"/>
        <path d="M6.74789 19.5999C5.32119 19.195 3.81737 20.0047 3.4125 21.4507C3.33538 21.6821 3.3161 21.9327 3.3161 22.1833V24.8825H6.01526C7.4998 24.8632 8.69514 23.6293 8.67586 22.1448C8.6373 20.9687 7.86611 19.9469 6.74789 19.5999Z" fill="#F44E27"/>
        <path d="M30.6162 17.3634L27.2037 13.9509H25.16V15.9946L28.5725 19.4071L25.16 22.8389V24.8825H27.2037L30.6162 21.4507L34.048 24.8825H36.0916V22.8389L32.6598 19.4071L36.0916 15.9946V13.9509H34.048L30.6162 17.3634Z" fill="#F44E27"/>
        <path d="M43.8421 13.7003C42.5503 13.7003 41.0272 14.4522 40.4681 15.3005V13.9509H37.5376V27.5431H40.4874V23.5522C41.0465 24.4005 42.5503 25.1524 43.8421 25.1524C47.0039 25.1524 49.3561 22.5882 49.3561 19.4456C49.3561 16.303 47.0039 13.7003 43.8421 13.7003ZM43.6493 22.4918C41.9527 22.4918 40.5645 21.1037 40.5645 19.4071C40.5645 17.7104 41.9527 16.3223 43.6493 16.3223C45.3459 16.3223 46.734 17.7104 46.734 19.4071C46.734 21.1229 45.3459 22.4918 43.6493 22.4918Z" fill="#F44E27"/>
        <path d="M86.5967 15.5685L86.8397 13.9336H89.4251L89.4039 23.6968C89.4251 27.6511 87.4161 29.9049 83.9689 29.9049C80.3463 29.9261 78.4684 27.6511 78.6014 25.1987H81.2736C81.4066 26.6794 82.467 27.385 83.859 27.385C85.4939 27.385 86.4868 26.369 86.4444 23.8954V22.8562C86.2458 23.585 85.3397 24.6242 83.485 24.6242C79.951 24.6242 78.5359 22.0176 78.5359 19.301C78.5359 16.3185 79.9491 13.6463 83.4406 13.6463C85.1161 13.6482 86.2883 14.6411 86.5967 15.5685ZM81.6052 19.2586C81.6052 20.6063 82.2453 22.0426 84.0788 22.0426C85.4033 22.0426 86.5524 21.2252 86.5524 19.5247V19.0157C86.5524 17.5138 85.7137 16.3647 84.0788 16.3647C82.1354 16.3647 81.6052 17.8454 81.6052 19.2586Z" fill="#F7F7F7"/>
        <path d="M97.2179 16.7619C95.6273 16.6077 94.0599 16.8506 94.0599 19.2143V24.9134H91.0773V13.9355H93.6627L93.8845 15.9232C94.2373 14.3327 95.3651 13.5595 97.2199 13.7369V16.7619H97.2179Z" fill="#F7F7F7"/>
        <path d="M97.7385 19.3916C97.7385 16.1006 100.123 13.5826 103.438 13.5826C106.84 13.5826 109.16 16.1006 109.16 19.3916C109.16 22.6383 106.885 25.2237 103.461 25.2237C100.037 25.2237 97.7385 22.6383 97.7385 19.3916ZM106.177 19.4572C106.177 17.9109 105.36 16.2317 103.461 16.2317C101.538 16.2317 100.744 17.9109 100.744 19.4572C100.744 20.9591 101.583 22.5496 103.461 22.5496C105.381 22.5496 106.177 20.9378 106.177 19.4572Z" fill="#F7F7F7"/>
        <path d="M123.911 24.9133H120.001L118.146 17.1378L116.247 24.9133H112.337L109.488 13.9335H112.713L114.437 22.4166L116.469 13.9335H119.783L121.838 22.4166L123.494 13.9335H126.697L123.911 24.9133Z" fill="#F7F7F7"/>
        <path d="M134.927 22.4166V24.8902C134.507 24.9558 134 25.0001 133.514 25.0001C130.819 25.0001 129.074 23.8954 129.074 21.069V16.4091H127.483V14.7086C129.803 13.8024 129.826 11.4175 129.803 11.2633H132.563C132.585 12.8539 132.187 13.494 131.68 13.9355H134.796V16.4091H132.012V20.7605C132.012 22.0638 132.563 22.4841 134 22.4841C134.331 22.4841 134.619 22.461 134.927 22.4166Z" fill="#F7F7F7"/>
        <path d="M139.169 15.3256C139.743 14.1996 141.091 13.6463 142.284 13.6463C144.758 13.6675 146.26 15.3468 146.26 17.9765V24.9134H143.256V18.2638C143.256 16.8506 142.373 16.2972 141.378 16.276C140.383 16.276 139.28 17.0279 139.28 18.6609V24.9134H136.298V9.38354H139.192L139.169 15.3256Z" fill="#F7F7F7"/>
      </svg>
    `;

    iframeClassKey = 'gh-container';

    isDevEnv = process.env.NODE_ENV === 'development';

    navItemActiveClass = 'active';

    private activeStateService: ActiveStateService;

    static observedAttributes = [
      selectedAccountIdAttrName,
      selectedAccountNameAttrName,
      isFixedAttrName,
    ];

    services: ServiceLinkItem[] = [];

    txMsgHref = process.env.TX_MSG_URL;

    user: CurrentUser | null = {} as CurrentUser;

    constructor() {
      super();

      this.activeStateService = new ActiveStateService(this.navItemActiveClass);
    }

    async attributeChangedCallback(
      name: string,
      oldValue: string,
      newValue: string,
    ) {
      switch (name) {
        case selectedAccountIdAttrName:
          if (oldValue !== null && oldValue !== newValue) {
            const accountName = this.attributes.getNamedItem(
              selectedAccountNameAttrName,
            )?.value;

            await this.setActiveAccount(this.shadowRoot as ShadowRoot, {
              name: accountName as string,
              id: Number(newValue),
              isSuper: false,
            });
          }
          break;
        default:
          break;
      }
    }

    async connectedCallback() {
      // Create a shadow root
      const shadow = this.attachShadow({ mode: 'open' });

      [
        [this.baseAuthUrl, 'baseAuthUrl is missing a value'],
        [this.clientId, 'clientId is missing a value'],
        [this.growthHubBaseUrl, 'growthHubBaseUrl is missing a value'],
        [this.growthHubHomeUrl, 'growthHubHomeUrl is missing a value'],
        [this.txMsgHref, 'txMsgHref is missing a value'],
      ].forEach(([variable, msg]) => invariant(variable, msg));

      this.fetchRetry = fetchRetry(window.fetch, {
        retries: 5,
        retryDelay: 1000,
        retryOn: async (attempt, _, response) => {
          if (
            (attempt < 5 &&
              response?.url.includes('/login') &&
              response?.redirected === true) ||
            (attempt < 5 && response?.status === 401)
          ) {
            await this.handleLogin(shadow, true);
            return true;
          }

          return false;
        },
      });

      await this.initGHComponent(shadow);
    }

    configureIntercom(shadow: ShadowRoot, appId: string) {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        };
        // @ts-expect-error: we don't need to type Intercom
        i.q = [];
        // @ts-expect-error: we don't need to type Intercom
        i.c = function (args) {
          i.q.push(args);
        };
        // @ts-expect-error: we don't need to type Intercom
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appId}`;
          document.body.appendChild(s);
        };
        l();
      }
    }

    // For now, clicked node will no longer be used because we're
    // not ready to implement self-upgrade flows in each app yet.
    // However, i'd like to maintain the functionality for the future,
    // so its it easy to implement again. With all of that said,
    // the next line will ignore eslint's callout about an unused param.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    displayModalContent(nextSelector: string, clickedNode: HTMLElement) {
      const modalContentNodes = Array.from(
        document.querySelectorAll<HTMLDivElement>(
          '.xpl-gh-dialog-content-group',
        ),
      );

      modalContentNodes.forEach((node) => {
        if (node.id === nextSelector) {
          node.removeAttribute('aria-hidden');
          return;
        }

        node.setAttribute('aria-hidden', 'true');
      });

      document.getElementById(this.dialogContentSelectors.buyNowLink);
    }

    showBuyNowModal(buttonNode: HTMLElement, shadow: ShadowRoot) {
      document
        .getElementById(this.dialogContentSelectors.buyNow)
        ?.setAttribute(
          'data-gh-service-name',
          buttonNode.dataset?.ghServiceName as string,
        );
      this.displayModalContent(this.dialogContentSelectors.buyNow, buttonNode);
      this.dialog?.show();
      const headerEle = shadow.querySelector('header');
      const growthHubEle = document.querySelector('growth-hub');

      headerEle?.classList.add('dialog--is-active');
      growthHubEle?.classList.add('dialog--is-active');

      this.dialog?.on('hide', () => {
        headerEle?.classList.remove('dialog--is-active');
        growthHubEle?.classList.remove('dialog--is-active');
      });
    }

    async initGHComponent(shadow: ShadowRoot) {
      const initialAccountName = this.attributes.getNamedItem(
        selectedAccountNameAttrName,
      );
      const initialAccountId = this.attributes.getNamedItem(
        selectedAccountIdAttrName,
      );
      const { value: isFixed = 'true' } =
        this.attributes.getNamedItem(isFixedAttrName) || {};

      document.addEventListener(xplEvts.afterAuthComplete, async () => {
        const name = initialAccountName?.value || '';
        const id = Number(initialAccountId?.value) || null;
        await this.setAccount(shadow, {
          name,
          id,
          isSuper: false,
        });

        this.buildNavigation(shadow);

        const curUrl = new URL(window.location.href);
        curUrl.searchParams.delete(this.GROWTH_HUB_QUERY_PARAM_KEY);
        window.history.replaceState(null, document.title, curUrl.toString());

        if (this.INTERCOM_APP_ID) {
          if (!this.user?.isSuper) {
            window.intercomSettings = {
              app_id: this.INTERCOM_APP_ID,
              email: this.user?.email,
              name: this.user?.fullName,
              user_hash: this.user?.userHash,
              user_id: this.user?.uuid,
            };
          } else {
            window.intercomSettings = {
              app_id: this.INTERCOM_APP_ID,
            };
          }

          this.configureIntercom(shadow, this.INTERCOM_APP_ID);
          window.Intercom('boot', window.intercomSettings);
        }

        this.checkIfShowBuyModal(shadow);

        this.dialog?.on('hide', () => this.checkIfShowBuyModal(shadow));

        window.addEventListener<'popstate'>('popstate', () => {
          this.checkIfShowBuyModal(shadow);
        });

        document
          ?.getElementById(this.dialogContentSelectors.buyNowLink)
          ?.querySelector('button')
          ?.addEventListener<'click'>('click', (e) => {
            e.preventDefault();

            const btn = e.target as HTMLButtonElement;
            const header = document
              .getElementById(this.dialogContentSelectors.buyNow)
              ?.querySelector('.xpl-gh-dialog-header h2') as HTMLElement;
            const body = document
              .getElementById(this.dialogContentSelectors.buyNow)
              ?.querySelector('.xpl-gh-dialog-body') as HTMLElement;
            const today = new Date(Date.now());

            const initialCta = {
              header: header?.textContent,
              body: body?.innerHTML,
              btn: btn.textContent,
            };

            const evtData = {
              accountName: this.user?.currentAccount.name,
              accountId: this.user?.currentAccount.id,
              email: this.user?.email,
              dateOfRequest: today.toDateString(),
            };

            const intercomPayload = [
              'trackEvent',
              `User Subscription Requested: ${
                btn.closest<HTMLDivElement>(
                  `#${this.dialogContentSelectors.buyNow}`,
                )?.dataset.ghServiceName
              }`,
              evtData,
            ] as const;

            if (this.isDevEnv) {
              // eslint-disable-next-line no-console
              console.log(...intercomPayload);
            }

            window.Intercom(...intercomPayload);

            const btnWrap = btn.closest('.xpl-button--default');
            btnWrap?.classList.add('xpl-button--success');
            btnWrap?.classList.remove('xpl-button--warning');

            header.innerText = 'Your request has been submitted';
            body.innerHTML =
              '<p>We&apos;ll be in touch once your account(s) have been granted access. In the meantime, <a href="https://docs.brandbot.com/en/collections/" target="_blank" rel="nofollow">check out our knowledge base videos and articles to get a head start!</a></p>';
            btn.innerText = "Request Sent! We'll be in touch";

            setTimeout(() => {
              btnWrap?.classList.remove('xpl-button--success');
              btnWrap?.classList.add('xpl-button--warning');

              btn.setAttribute('disabled', '');
            }, 5000);

            const replaceModalContentWInit = () => {
              btn.removeAttribute('disabled');

              header.innerText = initialCta.header as string;
              body.innerHTML = initialCta.body;
              btn.innerText = initialCta.btn as string;
            };
            this.dialog?.on('hide', () => {
              replaceModalContentWInit();

              this.dialog?.off('hide', replaceModalContentWInit);
            });
          });

        document
          .getElementById('dialog__go-back_button')
          ?.addEventListener('click', (e) => {
            e.preventDefault();

            history.back();
          });
      });

      this.setMarkup(shadow, isFixed !== 'false');
      this.setStyling(shadow);
      await this.handleLogin(shadow);
      this.listenForMessages(shadow);

      shadow
        .getElementById('main-nav')
        ?.addEventListener<'click'>('click', (evt) => {
          const clickedNode = evt.target as HTMLButtonElement;
          if (clickedNode.dataset.ghServiceIsSubscribed === 'false') {
            evt.preventDefault();

            this.showBuyNowModal(clickedNode, shadow);
          }

          const [linkElm, activeService] =
            this.activeStateService.getClickedService(evt, this.services);

          if (linkElm && activeService) {
            this.services = this.activeStateService.setActiveService(
              this.services,
              activeService.url,
            );

            this.activeStateService.setActiveNavItems(linkElm, activeService);
          }
        });
    }

    checkIfShowBuyModal(shadow: ShadowRoot) {
      Array.from(
        shadow.querySelectorAll<HTMLButtonElement>(
          '[data-gh-service-buy-link]',
        ),
      ).forEach((buttonNode) => {
        if (
          (buttonNode.dataset.ghServiceBuyLink || '').includes(
            window.location.origin,
          ) &&
          buttonNode.dataset.ghServiceBuyLink !== window.location.href &&
          buttonNode.dataset.ghServiceIsSubscribed !== 'true'
        ) {
          this.showBuyNowModal(buttonNode, shadow);
        }
      });
    }

    async setActiveAccount(
      shadow: ShadowRoot,
      accountObj: { id: number; name: string; isSuper: boolean },
    ) {
      const { id, name, isSuper } = accountObj;

      try {
        await this.fetch<BBUser>('/users/switch-membership', {
          method: 'POST',
          body: JSON.stringify({
            account_id: id,
          }),
        });

        this.dialog?.hide();

        const currentAccountDisplay = shadow.getElementById(
          this.currentAccountDisplaySelector,
        );

        if (currentAccountDisplay && name) {
          currentAccountDisplay.innerText = name;
        }

        const {
          data: {
            current_account: updatedCurrentAccount,
            email: updatedEmail,
            fullname: updatedFullName,
            user_hash: updatedUserHash,
            uuid: updatedUuid,
          },
        } = await this.fetch<BBUser>('/user');

        this.user = {
          currentAccount: {
            name: updatedCurrentAccount.name,
            id: updatedCurrentAccount.id,
          },
          email: updatedEmail,
          fullName: updatedFullName,
          isSuper,
          uuid: updatedUuid,
          userHash: updatedUserHash,
        };

        if (
          this.user?.currentAccount.name !== currentAccountDisplay?.innerText
        ) {
          if (currentAccountDisplay) {
            currentAccountDisplay.innerText =
              this.user?.currentAccount.name || '';
          }
        }

        window.XplGrowthHub.currentUser = this.user;

        this.buildNavigation(shadow);

        triggerEvent<typeof window.XplGrowthHub.currentUser>(
          xplEvts.afterAccountSelected,
          {
            detail: window.XplGrowthHub.currentUser,
          },
        );
      } catch (e) {
        // TODO: Add better error logging logic
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    checkIsAuthenticated() {
      const authCookie = getCookie(this.AUTH_SESSION_NAME);

      return !!authCookie;
    }

    setMarkup(shadow: ShadowRoot, isFixed = true) {
      shadow.innerHTML = `
        <header${isFixed !== false ? ' class="is--fixed"' : ''}>
          <nav id="main-nav">
            <a href="${this.growthHubHomeUrl}" style="display: block;">
              <figure id="main-logo">
                ${this.growthHubSVG}
              </figure>
            </a>
            <ul></ul>
          </nav>
          <nav id="account-details"></nav>
        </header>
        <section class="main-content__container">
          <slot name="main-content"></slot>
        </section>
      `;

      const dialogContainer = document.createElement('div');
      dialogContainer.classList.add('xpl-gh-dialog-container');
      dialogContainer.setAttribute('id', this.dialogSelector);
      dialogContainer.setAttribute('aria-hidden', 'true');
      dialogContainer.setAttribute('data-a11y-dialog', 'select-account');
      dialogContainer.innerHTML = `
        <div class="xpl-gh-dialog-overlay" data-a11y-dialog-hide></div>
        <div class="xpl-gh-dialog-content" role="document">
          <button
            data-a11y-dialog-hide
            class="xpl-gh-dialog-close"
            aria-label="Close this dialog window"
          >
            <svg class="svg-icon" viewBox="0 0 20 20">
              <path
                fill="#000000"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
              ></path>
            </svg>
          </button>

          <div class="xpl-gh-dialog-content-wrapper">
            <div class="xpl-gh-dialog-content-group" id="${this.dialogContentSelectors.accountSwitcher}" aria-hidden="true">
              <header class="xpl-gh-dialog-header">
                <h1 class="xpl-gh-text-title-3">Switch Account</h1>
                <form id="${this.dialogSearchFormSelector}">
                  <div class="form-body">
                    <div class="xpl-input">
                      <div class="xpl-input-wrapper">
                          <input id="search-by-name" name="search-by-name" placeholder="Search by Name" type="text" />
                      </div>
                    </div>
                    <div class="xpl-input">
                      <div class="xpl-input-wrapper">
                          <input id="search-by-id" name="search-by-id" placeholder="Search by Id" type="text" />
                      </div>
                    </div>
                  </div>
                </form>
              </header>
              <div class="xpl-gh-dialog-body"></div>
            </div>
            <div class="xpl-gh-dialog-content-group" id="${this.dialogContentSelectors.buyNow}" aria-hidden="true">
              <header class="xpl-gh-dialog-header">
                <h2 class="xpl-gh-text-title-3">We&apos;re glad to see you here!</h2>
              </header>
              <div class="xpl-gh-dialog-body">
                <p>Click &lsquo;Request Activation&rsquo; to request access to this product. Access will be granted on a rolling basis. By requesting activation, you hereby agree to <a href="https://docs.brandbot.com/en/articles/8973484-early-access-and-beta-policy" target="_blank" rel="nofollow">Xplor Growth&apos;s Early Access and Beta Policy</a>. Once activated, your beta period<sup>*</sup> will begin.</p>
                <p><small>*Gamification is excluded from the beta period</small></p>
              </div>
              <div class="xpl-gh-dialog-footer xpl-button-row">
                <div class="xpl-button-row-inner">
                  <div id="dialog__go-back_button" class="xpl-button xpl-button--default xpl-button--secondary">
                    <button>Go Back</button>
                  </div>
                  <div id="${this.dialogContentSelectors.buyNowLink}" class="xpl-button xpl-button--default xpl-button--warning xpl-button--primary">
                    <button>Request Activation</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;

      document
        ?.querySelector('[slot="main-content"]')
        ?.appendChild(dialogContainer);

      window?.XplGrowthHub?.setIsActive();
    }

    setStyling(shadow: ShadowRoot) {
      const apolloStyleElement = document.createElement('style');
      apolloStyleElement.textContent = APOLLO_CSS;
      shadow.appendChild(apolloStyleElement);

      const growthHubStyleElement = document.createElement('style');
      growthHubStyleElement.textContent = GROWTH_HUB_CSS;
      shadow.appendChild(growthHubStyleElement);

      const dialogStyleElement = document.createElement('style');
      dialogStyleElement.textContent = GROWTH_HUB_MODAL_CSS;
      document.head.appendChild(dialogStyleElement);
    }

    async handleLogin(shadow: ShadowRoot, isAuthRetry = false) {
      const curUrl = new URL(window.location.href);
      let ghQpStr = curUrl.searchParams.get(this.GROWTH_HUB_QUERY_PARAM_KEY);
      const ghQp = ghQpStr ? parseGhQp(ghQpStr) : null;

      if (isAuthRetry) {
        removeCookie(this.AUTH_SESSION_NAME);
        curUrl.searchParams.delete(this.GROWTH_HUB_QUERY_PARAM_KEY);
        window.history.replaceState(curUrl.toString(), document.title);
        ghQpStr = null;
      }

      const isAuthenticated = this.checkIsAuthenticated();

      if (isAuthenticated) {
        triggerEvent(xplEvts.afterAuthComplete);
        return Promise.resolve();
      }

      if (!isAuthenticated && ghQpStr) {
        const { code } = ghQp as GrowthHubQueryParam;

        invariant(code, 'No code parameter was found');

        const iframeContainer = document.createElement('div');
        iframeContainer.classList.add(this.iframeClassKey);

        const iframe = document.createElement('iframe');
        const iframeUrl = new URL(`${this.growthHubBaseUrl}/iframe-callback`);

        iframeUrl.searchParams.append('code', code);

        const codeVerifierCookie = getCookie(
          this.AUTH_CODE_VERIFIER_COOKIE_NAME,
        );

        invariant(
          codeVerifierCookie,
          'No code verifier cookie parameter was found',
        );

        iframeUrl.searchParams.append('code_verifier', codeVerifierCookie);

        [
          ['allowtransparency', 'true'],
          ['title', 'xpl_gh_portal'],
          ['src', iframeUrl.toString()],
        ].forEach(([attr, value]) => {
          iframe.setAttribute(attr, value);
        });

        iframe.style.backgroundColor = 'transparent';
        iframe.style.border = 'none';
        iframeContainer.appendChild(iframe);
        shadow.appendChild(iframeContainer);

        return Promise.resolve();
      }

      const { code_challenge: codeChallenge, code_verifier: codeVerifier } =
        pkceChallenge(128);

      setCookie(this.AUTH_CODE_VERIFIER_COOKIE_NAME, codeVerifier, {
        expires: 1,
      });

      const params = new URLSearchParams({
        code_challenge: codeChallenge,
        redirect_url: window.location.href,
      });

      const url = new URL(
        `${this.growthHubBaseUrl}/connector?${params.toString()}`,
      );

      window.location.assign(url.toString());

      return Promise.resolve();
    }

    async getServices(accountId: number): Promise<ServiceLinkItem[] | void> {
      try {
        const servicesArr = await this.fetch<Service[]>(
          `/services/${accountId}`,
          {},
          `${this.growthHubBaseUrl}/api/v1`,
        );

        return servicesArr.filter(
          ({ navItemType }) => navItemType !== 'none',
        ) as ServiceLinkItem[];
      } catch (e) {
        // TODO: Add better error logging logic
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    async fetch<T = undefined>(
      endpoint: string,
      config?: RequestInit,
      rootUrl = this.brandbotApiUrl,
    ): Promise<T> {
      const isAuthenticated = this.checkIsAuthenticated();

      try {
        const fetchConfig = { ...config };

        if (isAuthenticated) {
          fetchConfig.headers = {
            ...(fetchConfig.headers || {}),
            Authorization: `Bearer ${getCookie(this.AUTH_SESSION_NAME)}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          };
        }
        const res = await this.fetchRetry(`${rootUrl}${endpoint}`, fetchConfig);

        if (res.ok) return res.json() as T;

        return new Error(res.statusText) as T;
      } catch (err) {
        // TODO: replace with logger logic
        // eslint-disable-next-line no-console
        console.error('Fetch Failed:', err);

        throw err as T;
      }
    }

    buildMainNav(servicesArr: ServiceLinkItem[]) {
      return [
        ...new Set(
          servicesArr
            .filter((service) => service.navItemType === 'main_nav')
            .map((service) => service.category),
        ),
      ].reduce(
        (acc, category) => {
          const services = servicesArr.filter(
            (service) => service.category === category,
          );

          return [
            ...acc,
            {
              category,
              services,
            },
          ];
        },
        [] as {
          category: string;
          services: ServiceLinkItem[];
        }[],
      );
    }

    buildDDNav(servicesArr: Service[]) {
      return [
        ...new Set(
          servicesArr
            .filter(({ navItemType }) => navItemType === 'settings_nav')
            .map(({ name: text, url: link }) => ({
              text,
              link,
            })),
        ),
      ];
    }

    buildAccountPreloader(shadow: ShadowRoot) {
      const accountDetailsContainer = shadow.getElementById(
        this.accountDeetsContainerSelector,
      );
      const accountDetailsPreloader = document.createElement('div');
      accountDetailsPreloader?.classList.add('xpl-select-loader');
      accountDetailsPreloader.innerHTML = `
          <div class="load-wrapper">
            <div class="activity"></div>
          </div>
      `;

      accountDetailsContainer?.appendChild(accountDetailsPreloader);
    }

    buildCurrentAccountDisplay(
      shadow: ShadowRoot,
      currentAccount: BBUser['data']['current_account'],
    ) {
      const accountDetailsContainer = shadow.getElementById(
        this.accountDeetsContainerSelector,
      );
      const currentAccountDisplay = document.createElement('span');
      currentAccountDisplay.setAttribute(
        'id',
        this.currentAccountDisplaySelector,
      );
      currentAccountDisplay.classList.add('xpl-gh-text-title-5');
      currentAccountDisplay.innerText = currentAccount.name;
      accountDetailsContainer?.append(currentAccountDisplay);
    }

    buildCurrentUserAvi(
      shadow: ShadowRoot,
      fullName: BBUser['data']['fullname'],
    ) {
      const accountDetailsContainer = shadow.getElementById(
        this.accountDeetsContainerSelector,
      );
      const accountAvi = document.createElement('div');
      accountAvi.setAttribute('iconOnly', 'true');
      accountAvi.classList.add(
        'user-avatar',
        'xpl-button',
        'xpl-button--default',
        'xpl-button--primary',
      );

      accountAvi.innerHTML = `
        <button>
          ${
            fullName
              ? fullName
                  .split(' ')
                  .reduce((acc, [firstLetter]) => `${acc}${firstLetter}`, '')
              : `<xpl-icon size={24} icon="gear"></xpl-icon>`
          }
        </button>
      `;

      accountAvi?.querySelector('button')?.addEventListener('click', () => {
        shadow
          .querySelector('.xpl-dropdown')
          ?.classList.toggle(this.ddOpenSelector);
      });

      accountDetailsContainer?.append(accountAvi);

      document.addEventListener('click', (e) => {
        const clickedElementArr = e.composedPath();

        if (!clickedElementArr.includes(accountAvi)) {
          const ddElm = document
            ?.querySelector('growth-hub')
            ?.shadowRoot?.querySelector('.xpl-dropdown');
          const isDDOpen = ddElm?.classList.contains('xpl-dropdown--open');

          if (isDDOpen) ddElm?.classList.remove('xpl-dropdown--open');
        }
      });
    }

    buildAccountDropdown(shadow: ShadowRoot) {
      const accountDetailsContainer = shadow.getElementById(
        this.accountDeetsContainerSelector,
      );
      const accountDropdownContainer = document.createElement('div');
      accountDropdownContainer.classList.add('dropdown-container', 'dark');

      const accountDropdown = document.createElement('nav');
      accountDropdown.classList.add('xpl-dropdown');
      const staticDDOptions = [
        { text: 'Select Account', link: null },
        { text: 'Help Suite', link: 'https://docs.brandbot.com/en/' },
        { text: 'Logout', link: null },
      ];
      const ddOptions = [
        ...this.buildDDNav(this.services || []),
        ...staticDDOptions,
      ];

      accountDropdown.innerHTML = `<ul class="xpl-dropdown-list">${ddOptions.reduce(
        (acc, { text, link }) => `
          ${acc}
          <li
            class="xpl-dropdown-option xpl-dropdown-list-item"
            role="option"
            data-option-value="${text.toLowerCase().replace(' ', '-')}"
          >
            ${link ? `<a href="${link}" target="_blank" rel="nofollow">${text}</a>` : text}
          </li>
        `,
        '',
      )}</ul>`;

      accountDetailsContainer?.append(accountDropdownContainer);
      accountDropdownContainer.appendChild(accountDropdown);

      accountDropdown?.addEventListener('click', (e) => {
        const item = e.target as HTMLLIElement;
        const value = item?.dataset.optionValue;

        if (value) {
          switch (value) {
            case 'select-account':
              this.displayModalContent(
                this.dialogContentSelectors.accountSwitcher,
                item,
              );
              this.dialog?.show();
              accountDropdown.classList.remove(this.ddOpenSelector);
              return;
            case 'logout':
              this.logout();
              return;
            default:
              // TODO: replace with logger logic
              // eslint-disable-next-line no-console
              console.error(`${value} is not a valid option.`);
              break;
          }
        }
      });
    }

    removeAccountPreloader(shadow: ShadowRoot) {
      const accountPreloader = shadow.querySelector(
        '.xpl-select-loader',
      ) as Element;
      const accountDetailsContainer = shadow.getElementById(
        this.accountDeetsContainerSelector,
      );
      accountDetailsContainer?.classList.add('overflow-hidden');
      accountDetailsContainer?.removeChild(accountPreloader);
    }

    setAccountOptions(
      accounts: BBMemberships['memberships'],
      selectedAccountId?: number,
      isSuper = false,
    ) {
      const accountSelector = document.getElementById(
        this.accountPickerId,
      ) as HTMLUListElement;

      accountSelector.innerHTML = (accounts || [])
        ?.sort((a, b) => a.id - b.id)
        .reduce(generateAccountListReducer(selectedAccountId, isSuper), '');
    }

    buildAccountModal(
      shadow: ShadowRoot,
      accounts: BBMemberships['memberships'],
      selectedAccountId?: number,
      isSuperUser = false,
    ) {
      const modalContainer = document.getElementById(
        this.dialogSelector,
      ) as HTMLElement;
      this.dialog = new A11yDialog(modalContainer);
      const accountSelector = document.createElement('ul');
      accountSelector.setAttribute('id', this.accountPickerId);
      accountSelector.classList.add('xpl-list');
      modalContainer
        ?.querySelector('.xpl-gh-dialog-body')
        ?.appendChild(accountSelector);

      this.setAccountOptions(accounts, selectedAccountId, isSuperUser);

      accountSelector.addEventListener('click', async (e) => {
        const evt = e as PointerEvent;
        const target = evt.target as HTMLElement;

        if (target?.dataset?.ghAccountOption === 'true') {
          const prevSelectedItem = accountSelector.querySelector('li.selected');

          const nextSelectedItem = (e.target as HTMLButtonElement).closest(
            'li',
          );

          prevSelectedItem?.classList.remove('selected');
          nextSelectedItem?.classList.add('selected');
          try {
            const { ghAccountOptionName: name, ghAccountOptionId: id } = (
              evt.target as HTMLButtonElement
            )?.dataset;

            await this.setActiveAccount(shadow, {
              name: name as string,
              id: Number(id),
              isSuper: isSuperUser,
            });
          } catch (err) {
            // TODO: replace with logger logic
            // eslint-disable-next-line no-console
            console.error(err);

            prevSelectedItem?.classList.add('selected');
            nextSelectedItem?.classList.remove('selected');
          }
        }
      });

      this.dialog.on('hide', () => {
        const ghDialogSearch = document.getElementById(
          this.dialogSearchFormSelector,
        ) as HTMLFormElement;

        const searchFields = ghDialogSearch.querySelectorAll('input');
        Array.from(searchFields).forEach((input: HTMLInputElement) => {
          input.value = '';
          input.removeAttribute('disabled');
          input.closest('.xpl-input')?.classList.remove('xpl-input--disabled');
        });
      });
    }

    handleAccountsSearch(accounts: BBMemberships['memberships']) {
      const ghDialogSearch = document.getElementById(
        this.dialogSearchFormSelector,
      ) as HTMLFormElement;

      ghDialogSearch.addEventListener('keyup', (evt) => {
        const { name, type, value } = evt.target as HTMLInputElement;
        const searchFieldPrefix = 'search-by-';

        if (type === 'text' && name?.includes(searchFieldPrefix)) {
          const searchType = name?.replace(searchFieldPrefix, '');
          const theOtherSearch = ghDialogSearch
            .querySelector(
              `#${searchFieldPrefix}${searchType === 'name' ? 'id' : 'name'}`,
            )
            ?.closest('.xpl-input');

          if (value.length > 0) {
            this.setAccountOptions(
              accounts.filter(({ name: accountName, id: accountId }) => {
                theOtherSearch?.classList.add('xpl-input--disabled');
                theOtherSearch
                  ?.querySelector('input')
                  ?.setAttribute('disabled', '');

                if (searchType === 'name') {
                  return accountName
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
                if (searchType === 'id') {
                  return accountId.toString().includes(value);
                }
              }),
              this.user?.currentAccount.id,
              this.user?.isSuper,
            );
          } else {
            theOtherSearch?.classList.remove('xpl-input--disabled');
            theOtherSearch?.querySelector('input')?.removeAttribute('disabled');
            this.setAccountOptions(
              accounts,
              this.user?.currentAccount.id,
              this.user?.isSuper,
            );
          }
        }
      });
    }

    buildNavigation(shadow: ShadowRoot) {
      const generateMenuItem = ({
        category,
        isActive,
        services,
      }: (typeof navLinksWActive)[0]) => {
        const generateLink = ({
          hasChildren = false,
          isExternalService,
          isSubscribed = true,
          purchaseUrl,
          text,
          url,
          isActive: isChildActive,
        }: NavItem & { hasChildren?: boolean }) => {
          const isActiveState = hasChildren ? isActive : isChildActive;

          return `
            <a
              href="${url}"
              ${isActiveState ? ` class="active"` : ''}${isExternalService ? ' target="_blank" rel="noopener"' : ''}
              data-growth-hub-link
            >
              <button
                data-gh-service-name="${text}"
                data-gh-service-is-subscribed="${isSubscribed}"
                data-gh-service-buy-link="${purchaseUrl}"
              >${text}${
                hasChildren
                  ? `<span class="dd-arrow">${this.ddArrowSVG}</span>`
                  : ''
              }</button>
            </a>
          `;
        };

        return services.length === 1
          ? services.reduce(
              (acc, { name, hasAccess = false, ...rest }) =>
                `
                ${acc}
                <li>
                  ${generateLink({
                    isSubscribed: hasAccess,
                    text: name,
                    ...rest,
                  })}
                </li>
              `,
              '',
            )
          : `
            <li>
              ${generateLink({
                isExternalService: false,
                hasChildren: true,
                isActive,
                text: category,
                url: 'javascript:;',
              })}
              <ul>
                ${services.reduce(
                  (acc, { name, hasAccess = false, ...rest }) =>
                    `
                      ${acc}
                      <li>
                        ${generateLink({
                          isSubscribed: hasAccess,
                          text: name,
                          ...rest,
                        })}
                      </li>
                    `,
                  '',
                )}
              </ul>
            </li>
        `;
      };

      const navItems = this.buildMainNav(this.services || []);

      const navLinksWActive = navItems.map(({ services, ...props }) => ({
        ...props,
        services,
        isActive: !services.every(({ isActive }) => isActive === false),
      }));

      const navItemsHtml = navLinksWActive.reduce(
        (acc, serviceGroups) => `
            ${acc}
            ${generateMenuItem(serviceGroups)}
        `,
        '',
      );

      const navItemsContainer = shadow.querySelector('#main-nav ul');

      if (navItemsContainer) navItemsContainer.innerHTML = navItemsHtml;
    }

    async setAccount(
      shadow: ShadowRoot,
      {
        id: initialAccountId,
        isSuper: initialIsSuper,
        name: initialAccountName,
      }: { id: number | null; isSuper: boolean; name: string } = {
        id: null,
        name: '',
        isSuper: false,
      },
    ) {
      try {
        if (initialAccountId) {
          await this.setActiveAccount(shadow, {
            id: Number(initialAccountId),
            isSuper: initialIsSuper,
            name: initialAccountName,
          });
        }

        const {
          data: {
            current_account: currentAccount,
            email,
            fullname: fullName,
            is_super: isSuper,
            user_hash: userHash,
            uuid,
          },
        } = await this.fetch<BBUser>('/user');

        this.user = {
          currentAccount: {
            id: currentAccount.id,
            name: currentAccount.name,
          },
          email,
          fullName,
          isSuper,
          userHash,
          uuid,
        };

        if (!isSuper) {
          document
            .getElementById(this.dialogSearchFormSelector)
            ?.querySelector('#search-by-id')
            ?.closest('.xpl-input')
            ?.remove();
        }

        this.services =
          (await this.getServices(this.user?.currentAccount.id)) || [];

        this.services = this.activeStateService.setActiveService(
          this.services,
          window.location.href,
        );

        this.buildAccountPreloader(shadow);
        this.buildCurrentAccountDisplay(shadow, currentAccount);
        this.buildCurrentUserAvi(shadow, fullName);
        this.buildAccountDropdown(shadow);
        this.removeAccountPreloader(shadow);

        const { memberships: accounts } =
          await this.fetch<BBMemberships>('/users/memberships');

        this.accounts = accounts || [];

        this.buildAccountModal(
          shadow,
          accounts,
          this.user?.currentAccount.id,
          this.user?.isSuper,
        );
        this.handleAccountsSearch(accounts);

        setTimeout(() => {
          const accountDetailsContainer = shadow.getElementById(
            this.accountDeetsContainerSelector,
          );
          accountDetailsContainer?.classList.remove('overflow-hidden');
        }, 150);
      } catch (err) {
        // TODO: replace with logger logic
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    listenForMessages(shadow: ShadowRoot) {
      window.addEventListener('message', (evt) => {
        if (
          [this.txMsgHref, this.growthHubBaseUrl].includes(evt.origin) &&
          (evt.data as TemplateVars['iframeMsg']).source === 'growth-hub-msgs'
        ) {
          const msg = parsePostMsg(evt.data as string);

          if (msg?.accessToken) {
            const today = new Date(Date.now());
            const nextMonth = new Date(today);
            nextMonth.setDate(nextMonth.getDate() + 30);
            setCookie(this.AUTH_SESSION_NAME, msg?.accessToken, {
              expires: nextMonth,
            });
          }

          const iframeContainer = shadow.querySelector(
            `.${this.iframeClassKey}`,
          );

          if (iframeContainer) shadow.removeChild(iframeContainer);
          triggerEvent(xplEvts.afterAuthComplete);
        }
      });
    }

    logout() {
      removeCookie(this.AUTH_SESSION_NAME);
      triggerEvent(xplEvts.afterLogoutComplete);
    }
  }

  const generateXplGrowthHub = () => {
    let isActive = false;
    let currentUser = {
      currentAccount: {
        id: null as unknown as number,
        name: '',
      },
      email: '',
      fullName: '',
      isSuper: false,
      userHash: '',
      uuid: '',
    };

    return {
      get currentUser() {
        return currentUser;
      },
      set currentUser(updatedCurrentUser) {
        currentUser = updatedCurrentUser;
      },
      get isActive() {
        return isActive;
      },
      setIsActive() {
        isActive = true;
        return isActive;
      },
      init() {
        const styleElementId = 'data-growth-hub-client-css';
        const styleElement = document.createElement('style');

        styleElement.setAttribute(styleElementId, 'true');
        styleElement.textContent = `growth-hub:not(:defined) > * { display: none; }`;

        if (!document.querySelector(`[${styleElementId}]`)) {
          document.head.appendChild(styleElement);
        }

        const growthHubElmName = 'growth-hub';

        if (!customElements.get(growthHubElmName)) {
          customElements.define(growthHubElmName, GrowthHubElement);
        }
      },
    };
  };

  window.XplGrowthHub = generateXplGrowthHub();
})();
