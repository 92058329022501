import type { Service, ServiceLinkItem } from '@server/types';

export function getActiveService(
  servicesArr: Service[],
  currentUrl: string,
): ServiceLinkItem | undefined {
  return servicesArr
    .filter(({ url }) => currentUrl.startsWith(url))
    .find((_, idx, arr) => {
      const frags = arr.map(({ url }) => currentUrl.split(url)[1]);
      const shortestFrag = frags.reduce((a, b) =>
        a.length <= b.length ? a : b,
      );

      return idx === frags.findIndex((frag) => frag === shortestFrag);
    }) as ServiceLinkItem;
}
