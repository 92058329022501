.xpl-gh-dialog-container {
  font-family: apple-system, system-ui, "Segoe UI", Arial, Helvetica, Roboto, sans-serif;
}

.xpl-gh-dialog-container {
  --xpl-size-spacing-144: 8.75rem;
  --xpl-size-spacing-112: 7rem;
  --xpl-size-spacing-80: 5rem;
  --xpl-size-spacing-64: 4rem;
  --xpl-size-spacing-48: 3rem;
  --xpl-size-spacing-32: 2rem;
  --xpl-size-spacing-24: 1.5rem;
  --xpl-size-spacing-16: 1rem;
  --xpl-size-spacing-12: 0.75rem;
  --xpl-size-spacing-8: 0.5rem;
  --xpl-size-spacing-4: 0.25rem;
  --xpl-size-spacing-0: 0rem;
  --xpl-size-font-caption: 0.75rem;
  --xpl-size-font-callout: 0.75rem;
  --xpl-size-font-body: 0.875rem;
  --xpl-size-font-title-5: 0.875rem;
  --xpl-size-font-title-4: 1rem;
  --xpl-size-font-title-3: 1.25rem;
  --xpl-size-font-title-2: 1.5rem;
  --xpl-size-font-title-1: 1.875rem;
  --xpl-font-weight-normal: normal;
  --xpl-font-weight-medium: medium;
  --xpl-font-weight-bold: bold;
  --xpl-font-family-default: apple-system, system-ui, 'Segoe UI', Arial, Helvetica, Roboto, sans-serif;
  --xpl-color-transparent: rgba(255, 255, 255, 0);
  --xpl-color-red-900: #731409;
  --xpl-color-red-800: #961305;
  --xpl-color-red-700: #c2210f;
  --xpl-color-red-600: #db321f;
  --xpl-color-red-500: #f24f3d;
  --xpl-color-red-400: #f87263;
  --xpl-color-red-300: #ff9387;
  --xpl-color-red-200: #fec5be;
  --xpl-color-red-100: #fed9d5;
  --xpl-color-red-50: #fef7f3;
  --xpl-color-pink-900: #730b3f;
  --xpl-color-pink-800: #990f54;
  --xpl-color-pink-700: #bf1d7b;
  --xpl-color-pink-600: #cc3399;
  --xpl-color-pink-500: #e550c8;
  --xpl-color-pink-400: #f26ddc;
  --xpl-color-pink-300: #ff8df4;
  --xpl-color-pink-200: #ffb8f9;
  --xpl-color-pink-100: #ffd9fb;
  --xpl-color-pink-50: #fff0fe;
  --xpl-color-yellow-900: #5c5002;
  --xpl-color-yellow-800: #726916;
  --xpl-color-yellow-700: #8a8220;
  --xpl-color-yellow-600: #9e9828;
  --xpl-color-yellow-500: #adab20;
  --xpl-color-yellow-400: #c6cc4e;
  --xpl-color-yellow-300: #dce967;
  --xpl-color-yellow-200: #ecfd91;
  --xpl-color-yellow-100: #f3febb;
  --xpl-color-yellow-50: #fbffe5;
  --xpl-color-green-900: #154737;
  --xpl-color-green-800: #1d614b;
  --xpl-color-green-700: #24785d;
  --xpl-color-green-600: #2e9977;
  --xpl-color-green-500: #37b88f;
  --xpl-color-green-400: #4ad4a8;
  --xpl-color-green-300: #52ebba;
  --xpl-color-green-200: #74fbd0;
  --xpl-color-green-100: #c4ffed;
  --xpl-color-green-50: #ebfff8;
  --xpl-color-orange-900: #7a2714;
  --xpl-color-orange-800: #a6361a;
  --xpl-color-orange-700: #cc4121;
  --xpl-color-orange-600: #f44e27;
  --xpl-color-orange-500: #f76d43;
  --xpl-color-orange-400: #f99170;
  --xpl-color-orange-300: #ffbda8;
  --xpl-color-orange-200: #ffdccc;
  --xpl-color-orange-100: #feede4;
  --xpl-color-orange-50: #fef6f4;
  --xpl-color-purple-900: #330d80;
  --xpl-color-purple-800: #4d1ab2;
  --xpl-color-purple-700: #6923f4;
  --xpl-color-purple-600: #793ef7;
  --xpl-color-purple-500: #8857fa;
  --xpl-color-purple-400: #a480ff;
  --xpl-color-purple-300: #c3adff;
  --xpl-color-purple-200: #ddd1ff;
  --xpl-color-purple-100: #ebe5ff;
  --xpl-color-purple-50: #f4f2ff;
  --xpl-color-gray-1200: #18161f;
  --xpl-color-gray-1100: #201e29;
  --xpl-color-gray-1000: #292632;
  --xpl-color-gray-900: #302d3b;
  --xpl-color-gray-800: #363240;
  --xpl-color-gray-700: #3c3847;
  --xpl-color-gray-600: #6a6d7d;
  --xpl-color-gray-500: #999ba8;
  --xpl-color-gray-400: #e1e2e8;
  --xpl-color-gray-300: #eaebef;
  --xpl-color-gray-200: #eeeff3;
  --xpl-color-gray-100: #f3f4f6;
  --xpl-color-gray-50: #f8f9fa;
  --xpl-color-gray-0: #ffffff;
  --xpl-color-text-warning-dm: var(--xpl-color-red-300);
  --xpl-color-text-warning-lm: var(--xpl-color-red-700);
  --xpl-color-text-reverse-dm: var(--xpl-color-gray-1000);
  --xpl-color-text-reverse-lm: var(--xpl-color-gray-0);
  --xpl-color-text-secondary-dm: var(--xpl-color-gray-500);
  --xpl-color-text-secondary-lm: var(--xpl-color-gray-600);
  --xpl-color-text-primary-dm: var(--xpl-color-gray-0);
  --xpl-color-text-primary-lm: var(--xpl-color-gray-900);
  --xpl-color-action-warning-hover-dm: var(--xpl-color-red-400);
  --xpl-color-action-warning-hover-lm: var(--xpl-color-red-800);
  --xpl-color-action-warning-dm: var(--xpl-color-red-300);
  --xpl-color-action-warning-lm: var(--xpl-color-red-700);
  --xpl-color-action-disabled-dm: var(--xpl-color-gray-800);
  --xpl-color-action-disabled-lm: var(--xpl-color-gray-100);
  --xpl-color-action-primary-hover-dm: var(--xpl-color-purple-400);
  --xpl-color-action-primary-hover-lm: var(--xpl-color-purple-800);
  --xpl-color-action-primary-dm: var(--xpl-color-purple-300);
  --xpl-color-action-primary-lm: var(--xpl-color-purple-700);
  --xpl-color-trivial-bg-dm: var(--xpl-color-transparent);
  --xpl-color-trivial-bg-lm: var(--xpl-color-gray-400);
  --xpl-color-trivial-dm: var(--xpl-color-gray-500);
  --xpl-color-trivial-lm: var(--xpl-color-gray-600);
  --xpl-color-information-bg-dm: var(--xpl-color-transparent);
  --xpl-color-highlight-bg-dm: var(--xpl-color-transparent);
  --xpl-color-highlight-bg-lm: var(--xpl-color-yellow-200);
  --xpl-color-highlight-dm: var(--xpl-color-yellow-200);
  --xpl-color-highlight-lm: var(--xpl-color-yellow-500);
  --xpl-color-negative-bg-dm: var(--xpl-color-transparent);
  --xpl-color-negative-bg-lm: var(--xpl-color-red-100);
  --xpl-color-negative-dm: var(--xpl-color-red-300);
  --xpl-color-negative-lm: var(--xpl-color-red-600);
  --xpl-color-positive-bg-dm: var(--xpl-color-transparent);
  --xpl-color-positive-bg-lm: var(--xpl-color-green-100);
  --xpl-color-positive-dm: var(--xpl-color-green-300);
  --xpl-color-positive-lm: var(--xpl-color-green-500);
  --xpl-color-secondary-bg-dm: var(--xpl-color-gray-900);
  /* themable */
  --xpl-color-secondary-bg-lm: var(--xpl-color-purple-50);
  /* themable */
  --xpl-color-secondary-hover-dm: var(--xpl-color-purple-400);
  /* themable */
  --xpl-color-secondary-hover-lm: var(--xpl-color-purple-800);
  /* themable */
  --xpl-color-secondary-dm: var(--xpl-color-purple-300);
  /* themable */
  --xpl-color-secondary-lm: var(--xpl-color-purple-700);
  /* themable */
  --xpl-color-primary-bg-dm: var(--xpl-color-gray-900);
  /* themable */
  --xpl-color-primary-bg-lm: var(--xpl-color-orange-50);
  /* themable */
  --xpl-color-primary-dm: var(--xpl-color-orange-400);
  /* themable */
  --xpl-color-primary-lm: var(--xpl-color-orange-600);
  /* themable */
  --xpl-color-stroke-dm: var(--xpl-color-gray-700);
  --xpl-color-stroke-lm: var(--xpl-color-gray-400);
  --xpl-color-navigation-dm: var(--xpl-color-gray-1000);
  --xpl-color-navigation-lm: var(--xpl-color-gray-1000);
  --xpl-color-background-2-dm: var(--xpl-color-gray-1100);
  --xpl-color-background-2-lm: var(--xpl-color-gray-100);
  --xpl-color-background-dm: var(--xpl-color-gray-1200);
  --xpl-color-background-lm: var(--xpl-color-gray-50);
  --xpl-color-foreground-dm: var(--xpl-color-gray-1000);
  --xpl-color-foreground-lm: var(--xpl-color-gray-0);
  --xpl-color-accent-purple: var(--xpl-color-purple-300);
  --xpl-color-accent-pink: var(--xpl-color-pink-300);
  --xpl-color-accent-yellow: var(--xpl-color-yellow-200);
  --xpl-color-accent-green: var(--xpl-color-green-200);
  --xpl-color-text-action-dm: var(--xpl-color-primary-dm);
  /* uses themable token */
  --xpl-color-text-action-lm: var(--xpl-color-primary-lm);
  /* uses themable token */
  --xpl-color-information-bg-lm: var(--xpl-color-primary-bg-lm);
  /* uses themable token */
  --xpl-color-information-dm: var(--xpl-color-primary-dm);
  /* uses themable token */
  --xpl-color-information-lm: var(--xpl-color-primary-lm);
  /* uses themable token */
}

.xpl-gh-dialog-container [class^="xpl-"],
.xpl-gh-dialog-container [class^="xpl-"] *,
.xpl-gh-dialog-container [class^="xpl-"]::before,
.xpl-gh-dialog-container [class^="xpl-"] *::before,
.xpl-gh-dialog-container [class^="xpl-"]::after,
.xpl-gh-dialog-container [class^="xpl-"] *::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

[class^="xpl-"] .xpl-gh-dialog-container ol,
.xpl-gh-dialog-container ol[class^="xpl-"],
[class^="xpl-"] .xpl-gh-dialog-container ul,
.xpl-gh-dialog-container ul[class^="xpl-"] {
  list-style: none;
  margin: 0;
  padding: 0;
}

.xpl-gh-dialog-container blockquote,
.xpl-gh-dialog-container dl,
.xpl-gh-dialog-container dd,
.xpl-gh-dialog-container h1,
.xpl-gh-dialog-container h2,
.xpl-gh-dialog-container h3,
.xpl-gh-dialog-container h4,
.xpl-gh-dialog-container h5,
.xpl-gh-dialog-container h6,
.xpl-gh-dialog-container hr,
.xpl-gh-dialog-container figure,
.xpl-gh-dialog-container p,
.xpl-gh-dialog-container pre {
  margin: 0;
}

[class^=xpl-] .xpl-gh-dialog-container button,
.xpl-gh-dialog-container button[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container input,
.xpl-gh-dialog-container input[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container optgroup,
.xpl-gh-dialog-container optgroup[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container select,
.xpl-gh-dialog-container select[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container textarea,
.xpl-gh-dialog-container textarea[class^=xpl-] {
  line-height: inherit;
  color: inherit;
}

[class^=xpl-] .xpl-gh-dialog-container button,
.xpl-gh-dialog-container button[class^=xpl-] {
  background-color: transparent;
  background-image: none;
  cursor: pointer;
}

[class^=xpl-] .xpl-gh-dialog-container button,
.xpl-gh-dialog-container button[class^=xpl-] [class^=xpl-] [type=button],
.xpl-gh-dialog-container [type=button][class^=xpl-] {
  -webkit-appearance: none;
}

[class^=xpl-] .xpl-gh-dialog-container button,
.xpl-gh-dialog-container button[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container select,
.xpl-gh-dialog-container select[class^=xpl-] {
  text-transform: none;
}

[class^=xpl-] .xpl-gh-dialog-container button,
.xpl-gh-dialog-container button[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container input,
.xpl-gh-dialog-container input[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container optgroup,
.xpl-gh-dialog-container optgroup[class^=xpl-],
[class^=xpl-] .xpl-gh-dialog-container select,
.xpl-gh-dialog-container select[class^=xpl-] [class^=xpl-] textarea,
.xpl-gh-dialog-container textarea[class^=xpl-] {
  font-family: var(--xpl-font-family-default);
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.xpl-gh-dialog-container [aria-hidden="true"] {
  display: none;
}

.xpl-gh-text-title-1,
[class^="xpl-"] .xpl-gh-text-title-1 {
  font-size: var(--xpl-size-font-title-1);
  font-weight: bold;
  line-height: 1.2;
}

.xpl-gh-text-title-2,
[class^="xpl-"] .xpl-gh-text-title-2 {
  font-size: var(--xpl-size-font-title-2);
  font-weight: bold;
  line-height: 1.33333333;
}

.xpl-gh-text-title-3,
[class^="xpl-"] .xpl-gh-text-title-3 {
  font-size: var(--xpl-size-font-title-3);
  font-weight: bold;
  line-height: 1.4;
}

.xpl-gh-text-title-4,
[class^="xpl-"] .xpl-gh-text-title-4 {
  font-size: var(--xpl-size-font-title-4);
  font-weight: 500;
  line-height: 1.5;
}

.xpl-gh-text-title-5,
[class^="xpl-"] .xpl-gh-text-title-5 {
  font-size: var(--xpl-size-font-title-5);
  font-weight: 500;
  line-height: 1.42857143;
}

.xpl-gh-dialog-content #account-picker {
  border-width: 1px;
}

.xpl-gh-dialog-content .xpl-list-item {
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(225 226 232 / var(--tw-border-opacity));
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(48 45 59 / var(--tw-text-opacity));
  text-decoration-line: none
}

.dark .xpl-gh-dialog-content .xpl-list-item {
  --tw-border-opacity: 1;
  border-color: rgb(106 109 125 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(48 45 59 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.xpl-gh-dialog-content .xpl-list-left {
  margin-right: 2rem;
  display: flex;
  flex-shrink: 0
}

.xpl-gh-dialog-content .xpl-list-right div:not(:last-child) {
  margin-bottom: 0.25rem
}

.xpl-gh-dialog-content a.xpl-list-item {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.44187 6.3187L1.93773 11.8678C1.76151 12.0441 1.47655 12.0441 1.30033 11.8678L0.557948 11.1254C0.381726 10.9492 0.381726 10.6643 0.557948 10.488L5.001 6L0.557948 1.51195C0.381726 1.33573 0.381726 1.05077 0.557948 0.874551L1.30033 0.132167C1.47655 -0.0440556 1.76151 -0.0440556 1.93773 0.132167L7.44187 5.6813C7.6181 5.85752 7.6181 6.14248 7.44187 6.3187Z' fill='%236A6D7D'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  padding-right: 2.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.xpl-gh-dialog-content a.xpl-list-item:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.dark .xpl-gh-dialog-content a.xpl-list-item {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.44187 6.3187L1.93773 11.8678C1.76151 12.0441 1.47655 12.0441 1.30033 11.8678L0.557948 11.1254C0.381726 10.9492 0.381726 10.6643 0.557948 10.488L5.001 6L0.557948 1.51195C0.381726 1.33573 0.381726 1.05077 0.557948 0.874551L1.30033 0.132167C1.47655 -0.0440556 1.76151 -0.0440556 1.93773 0.132167L7.44187 5.6813C7.6181 5.85752 7.6181 6.14248 7.44187 6.3187Z' fill='%23999BA8'/%3E%3C/svg%3E%0A");
}

.xpl-gh-dialog-content a.xpl-list-item:focus {
  border-color: var(--xpl-color-secondary-lm);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dark .xpl-gh-dialog-content a.xpl-list-item:focus {
  border-color: var(--xpl-color-secondary-dm);
}

.xpl-gh-dialog-content .xpl-list-item:last-child {
  margin-bottom: 0px
}

.xpl-gh-dialog-container,
.xpl-gh-dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.xpl-gh-dialog-container {
  z-index: 9999;
  display: flex;
}

.xpl-gh-dialog-container[aria-hidden='true'] {
  display: none;
}

.xpl-gh-dialog-overlay {
  background-color: rgb(43 46 56 / 0.9);
}

.xpl-gh-dialog-content {
  background-color: white;
  margin: auto;
  position: relative;
  z-index: 2;
}

.xpl-gh-dialog-content {
  overflow: hidden;
  max-height: 90vh;
}

.xpl-gh-dialog-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 0;
  padding: 0.25em;
  background-color: transparent;
  font-size: 1.5em;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;
  border-radius: 50%;
}

.xpl-gh-dialog-close:hover {
  background-color: rgb(50 50 0 / 0.15);
}

.xpl-gh-dialog-content {
  border-radius: 0.3125rem;
  padding-bottom: 1rem;
  width: 39.375rem;
}

.xpl-gh-dialog-header,
.xpl-gh-dialog-body {
  padding: 1rem;
}

[class^=xpl-] .xpl-gh-dialog-header {
  border-width: 1px;
  border-color: rgb(225 226 232 / 1);
}

.xpl-gh-dialog-header #xpl-gh-dialog-search {
  margin-top: 1rem;
}

.xpl-gh-dialog-header #xpl-gh-dialog-search .xpl-input:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.xpl-gh-dialog-body {
  height: 100%;
  height: 29rem;
  overflow: hidden;
}

.xpl-gh-dialog-body .xpl-list {
  border-width: 1px;
  border-color: rgb(225 226 232 / 1);
  max-height: 98%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.xpl-gh-dialog-body .xpl-list-item {
  border-radius: 0;
  margin-bottom: 0;
  border: none;
}

.xpl-gh-dialog-body li.selected {
  order: 0;
}

.xpl-gh-dialog-body li:not(.selected) {
  order: 1;
}

.xpl-gh-dialog-body li.selected .xpl-list-item {
  background: rgb(105 35 244 / 25%);
  border-color: rgb(105 35 244);
}

.xpl-gh-dialog-body li:not(:first-of-type) .xpl-list-item {
  border-top: 1px solid rgb(225 226 232 / 1);
}

.xpl-gh-dialog-content .xpl-gh-dialog-footer {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

growth-hub.dialog--is-active .xpl-gh-dialog-overlay {
  top: 3.75rem;
}

#dialog__buy-now .xpl-gh-dialog-body {
  height: auto;
}

#dialog__buy-now .xpl-gh-dialog-body .xpl-gh-text-title-2 {
  margin-bottom: 1rem;
}

#dialog__buy-now .xpl-gh-dialog-body p {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
}

#dialog__buy-now .xpl-gh-dialog-body p:not(:last-of-type) {
  margin-bottom: .5rem;
}

.xpl-gh-dialog-container p a {
  color: var(--xpl-color-action-primary-lm);
  /* Body Link */
  font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.xpl-gh-dialog-container [class^="xpl-"],
.xpl-gh-dialog-container [class^="xpl-"] * {
  font-family: var(--xpl-font-family-default);
  line-height: 1.5;
}

.xpl-gh-dialog-container [class^="xpl-"] {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

.xpl-gh-dialog-container .xpl-button button,
.xpl-gh-dialog-container .xpl-button a {
  align-items: center;
  align-content: center;
  background-color: var(--xpl-color-secondary-lm);
  border-radius: var(--xpl-button-radius);
  display: inline-grid;
  gap: 6px;
  grid-auto-flow: column;
  height: 40px;
  justify-content: center;
  justify-items: center;
  padding: 10px 18px 10px 20px;
  text-decoration: none;
  white-space: nowrap;
}

.xpl-gh-dialog-container .xpl-button button svg,
.xpl-gh-dialog-container .xpl-button a svg {
  width: 15px;
  height: 15px;
}

.xpl-gh-dialog-container .xpl-button button:hover,
.xpl-gh-dialog-container .xpl-button a:hover {
  background-color: var(--xpl-color-secondary-hover-lm);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.xpl-gh-dialog-container .xpl-button button:active,
.xpl-gh-dialog-container .xpl-button a:active {
  --tw-ring-color: var(--xpl-color-purple-500);

  background-color: var(--xpl-color-secondary-hover-lm);

  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);

  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

  --tw-ring-offset-width: 2px;
}

.xpl-gh-dialog-container .xpl-button button:focus,
.xpl-gh-dialog-container .xpl-button a:focus {
  --tw-ring-color: var(--xpl-color-purple-500);

  border-color: var(--xpl-color-secondary-lm);

  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);

  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

  --tw-ring-offset-width: 2px;
}

.xpl-gh-dialog-container .xpl-button button:disabled,
.xpl-gh-dialog-container .xpl-button button:disabled:hover,
.xpl-gh-dialog-container .xpl-button a:disabled,
.xpl-gh-dialog-container .xpl-button a:disabled:hover {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
  cursor: not-allowed;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.xpl-gh-dialog-container .xpl-button button,
.xpl-gh-dialog-container .xpl-button a {
  cursor: pointer;
  border-radius: 9999px;
  border-width: 1px;
  border-color: transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.xpl-gh-dialog-container .xpl-button button:focus,
.xpl-gh-dialog-container .xpl-button a:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.xpl-gh-dialog-container .xpl-button--sm button,
.xpl-gh-dialog-container .xpl-button--sm a,
.xpl-gh-dialog-container .xpl-button--xs button,
.xpl-gh-dialog-container .xpl-button--xs a {
  font-size: 0.75rem;
}

.xpl-gh-dialog-container .xpl-button--sm button svg,
.xpl-gh-dialog-container .xpl-button--sm a svg,
.xpl-gh-dialog-container .xpl-button--xs button svg,
.xpl-gh-dialog-container .xpl-button--xs a svg {
  width: 12px;
}

.xpl-gh-dialog-container .xpl-button--sm button,
.xpl-gh-dialog-container .xpl-button--sm a {
  height: 32px;
  padding: 8px 16px;
}

.xpl-gh-dialog-container .xpl-button--xs button,
.xpl-gh-dialog-container .xpl-button--xs a {
  height: 24px;
  padding: 4px 10px;
}

.xpl-gh-dialog-container .xpl-button--icon-only button,
.xpl-gh-dialog-container .xpl-button--icon-only a {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 40px;
}

.xpl-gh-dialog-container .xpl-button--icon-only button svg,
.xpl-gh-dialog-container .xpl-button--icon-only a svg {
  height: 18px;
  width: 18px;
}

.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--sm a,
.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--sm button {
  height: 32px;
  width: 32px;
}

.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--sm a svg,
.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--sm button svg {
  height: 15px;
  width: 15px;
}

.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--xs a,
.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--xs button {
  height: 24px;
  width: 24px;
}

.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--xs a svg,
.xpl-gh-dialog-container .xpl-button--icon-only.xpl-button--xs button svg {
  height: 12px;
  width: 12px;
}

.xpl-gh-dialog-container .xpl-button--secondary a:hover,
.xpl-gh-dialog-container .xpl-button--secondary button:hover {
  border-color: var(--xpl-color-secondary-lm);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--secondary a:active,
.xpl-gh-dialog-container .xpl-button--secondary button:active {
  --tw-ring-color: var(--xpl-color-purple-500);

  border-color: var(--xpl-color-action-primary-lm);

  --tw-bg-opacity: 1;

  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--secondary a:focus,
.xpl-gh-dialog-container .xpl-button--secondary button:focus {
  --tw-ring-color: var(--xpl-color-purple-500);
  --tw-border-opacity: 1;
  border-color: rgb(234 235 239 / var(--tw-border-opacity));
}

.xpl-gh-dialog-container .xpl-button--secondary a,
.xpl-gh-dialog-container .xpl-button--secondary button {
  --tw-border-opacity: 1;
  border-color: rgb(234 235 239 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(48 45 59 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-button--subtle a:hover,
.xpl-gh-dialog-container .xpl-button--subtle button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-button--subtle a:active,
.xpl-gh-dialog-container .xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-400);
  --tw-bg-opacity: 1;
  background-color: rgb(234 235 239 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--subtle a:focus,
.xpl-gh-dialog-container .xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-400);

  border-color: transparent;

  --tw-bg-opacity: 1;

  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--subtle a,
.xpl-gh-dialog-container .xpl-button--subtle button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(48 45 59 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-button--success a:hover,
.xpl-gh-dialog-container .xpl-button--success button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 97 75 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-button--success a:active,
.xpl-gh-dialog-container .xpl-button--success button:active {
  --tw-ring-color: var(--xpl-color-green-600);
  --tw-bg-opacity: 1;
  background-color: rgb(29 97 75 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--success a:focus,
.xpl-gh-dialog-container .xpl-button--success button:focus {
  --tw-ring-color: var(--xpl-color-green-600);

  border-color: transparent;

  --tw-bg-opacity: 1;

  background-color: rgb(36 120 93 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--success a,
.xpl-gh-dialog-container .xpl-button--success button {
  --tw-bg-opacity: 1;
  background-color: rgb(36 120 93 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button {
  background-color: transparent;
  color: var(--xpl-color-green-600);
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:hover,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:hover {
  background-color: transparent;
  border-color: var(--xpl-color-green-600);
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:active,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:active {
  background-color: transparent;
  border-color: var(--xpl-color-green-600);
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:focus,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:focus {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgb(234 235 239 / var(--tw-border-opacity));
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:disabled,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:disabled:hover,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:disabled,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:disabled:hover {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:hover,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:active,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-400);
  --tw-bg-opacity: 1;
  background-color: rgb(234 235 239 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:focus,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-400);

  border-color: transparent;

  --tw-bg-opacity: 1;

  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:disabled,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:disabled:hover,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:disabled,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:disabled:hover {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
}

.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a,
.xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(36 120 93 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-button--warning a,
.xpl-gh-dialog-container .xpl-button--warning button {
  background-color: var(--xpl-color-action-warning-lm);
}

.xpl-gh-dialog-container .xpl-button--warning a:hover,
.xpl-gh-dialog-container .xpl-button--warning button:hover {
  background-color: var(--xpl-color-action-warning-hover-lm);
}

.xpl-gh-dialog-container .xpl-button--warning a:active,
.xpl-gh-dialog-container .xpl-button--warning button:active {
  --tw-ring-color: var(--xpl-color-red-700);

  background-color: var(--xpl-color-action-warning-hover-lm);
}

.xpl-gh-dialog-container .xpl-button--warning a:focus,
.xpl-gh-dialog-container .xpl-button--warning button:focus {
  --tw-ring-color: var(--xpl-color-action-warning-lm);

  background-color: var(--xpl-color-action-warning-lm);
  border-color: transparent;
}

.xpl-gh-dialog-container .xpl-button--warning a,
.xpl-gh-dialog-container .xpl-button--warning button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button {
  background-color: transparent;
  color: var(--xpl-color-text-warning-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:hover,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:hover {
  background-color: transparent;
  border-color: var(--xpl-color-text-warning-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:active,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:active {
  background-color: transparent;
  border-color: var(--xpl-color-text-warning-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:focus,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:focus {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgb(234 235 239 / var(--tw-border-opacity));
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:disabled,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:disabled:hover,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:disabled,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:disabled:hover {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button {
  color: var(--xpl-color-text-warning-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:hover,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:active,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-400);
  --tw-bg-opacity: 1;
  background-color: rgb(234 235 239 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:focus,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-400);

  border-color: transparent;

  --tw-bg-opacity: 1;

  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:disabled,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:disabled:hover,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:disabled,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:disabled:hover {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
}

.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a,
.xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button a,
.dark .xpl-gh-dialog-container .xpl-button button {
  background-color: var(--xpl-color-secondary-dm);
  color: var(--xpl-color-text-reverse-dm);
}

.dark .xpl-gh-dialog-container .xpl-button a:hover,
.dark .xpl-gh-dialog-container .xpl-button button:hover {
  background-color: var(--xpl-color-secondary-hover-dm);
  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button a:focus,
.dark .xpl-gh-dialog-container .xpl-button button:focus {
  --tw-ring-color: var(--xpl-color-purple-500);
  --tw-ring-offset-color: var(--xpl-color-foreground-dm);

  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button a:active,
.dark .xpl-gh-dialog-container .xpl-button button:active {
  --tw-ring-color: var(--xpl-color-purple-500);
  --tw-ring-offset-color: var(--xpl-color-foreground-dm);

  background-color: var(--xpl-color-secondary-hover-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a,
.dark .xpl-gh-dialog-container .xpl-button--secondary button {
  border-color: var(--xpl-color-gray-600);
  background-color: var(--xpl-color-foreground-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:hover,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:active,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:focus {
  background-color: var(--xpl-color-foreground-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:hover {
  border-color: var(--xpl-color-secondary-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:active {
  border-color: var(--xpl-color-secondary-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--secondary button:focus {
  --tw-ring-color: var(--xpl-color-secondary-dm);

  border-color: var(--xpl-color-gray-600);
}

.dark .xpl-gh-dialog-container .xpl-button--secondary a,
.dark .xpl-gh-dialog-container .xpl-button--secondary button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button--subtle a:hover,
.dark .xpl-gh-dialog-container .xpl-button--subtle button:hover {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(54 50 64 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--subtle a:active,
.dark .xpl-gh-dialog-container .xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-700);
  --tw-bg-opacity: 1;
  background-color: rgb(60 56 71 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--subtle a:focus,
.dark .xpl-gh-dialog-container .xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-700);

  background-color: var(--xpl-color-foreground-dm);
  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button--subtle a,
.dark .xpl-gh-dialog-container .xpl-button--subtle button {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button--success a,
.dark .xpl-gh-dialog-container .xpl-button--success button {
  color: var(--xpl-color-reverse-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--success a:hover,
.dark .xpl-gh-dialog-container .xpl-button--success button:hover {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(74 212 168 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--success a:active,
.dark .xpl-gh-dialog-container .xpl-button--success button:active {
  --tw-ring-color: var(--xpl-color-green-200);
  --tw-bg-opacity: 1;
  background-color: rgb(74 212 168 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--success a:focus,
.dark .xpl-gh-dialog-container .xpl-button--success button:focus {
  --tw-ring-color: var(--xpl-color-green-200);

  border-color: transparent;

  --tw-bg-opacity: 1;

  background-color: rgb(116 251 208 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--success a,
.dark .xpl-gh-dialog-container .xpl-button--success button {
  --tw-bg-opacity: 1;
  background-color: rgb(116 251 208 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button {
  background-color: var(--xpl-color-foreground-dm);
  color: var(--xpl-color-green-200);
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:hover,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:active,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:focus {
  background-color: var(--xpl-color-foreground-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:hover {
  border-color: var(--xpl-color-green-200);
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:active {
  border-color: var(--xpl-color-green-200);
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--secondary button:focus {
  border-color: var(--xpl-color-stroke-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:hover,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(54 50 64 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:active,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-700);
  --tw-bg-opacity: 1;
  background-color: rgb(60 56 71 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a:focus,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-700);

  background-color: var(--xpl-color-foreground-dm);
  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle a,
.dark .xpl-gh-dialog-container .xpl-button--success.xpl-button--subtle button {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(116 251 208 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-button--warning a,
.dark .xpl-gh-dialog-container .xpl-button--warning button {
  background-color: var(--xpl-color-action-warning-dm);
  color: var(--xpl-color-reverse-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning a:hover,
.dark .xpl-gh-dialog-container .xpl-button--warning button:hover {
  border-color: transparent;
  background-color: var(--xpl-color-action-warning-hover-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning a:active,
.dark .xpl-gh-dialog-container .xpl-button--warning button:active {
  --tw-ring-color: var(--xpl-color-red-300);

  background-color: var(--xpl-color-action-warning-hover-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning a:focus,
.dark .xpl-gh-dialog-container .xpl-button--warning button:focus {
  --tw-ring-color: var(--xpl-color-red-300);

  background-color: var(--xpl-color-action-warning-dm);
  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button {
  background-color: var(--xpl-color-foreground-dm);
  color: var(--xpl-color-text-warning-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:hover,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:active,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:focus {
  background-color: var(--xpl-color-foreground-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:hover,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:hover {
  border-color: var(--xpl-color-text-warning-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:active,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:active {
  border-color: var(--xpl-color-text-warning-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary a:focus,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--secondary button:focus {
  border-color: var(--xpl-color-stroke-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button {
  color: var(--xpl-color-text-warning-dm);
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:hover,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:hover {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(54 50 64 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:active,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:active {
  --tw-ring-color: var(--xpl-color-gray-700);
  --tw-bg-opacity: 1;
  background-color: rgb(60 56 71 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a:focus,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button:focus {
  --tw-ring-color: var(--xpl-color-gray-700);

  background-color: var(--xpl-color-foreground-dm);
  border-color: transparent;
}

.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle a,
.dark .xpl-gh-dialog-container .xpl-button--warning.xpl-button--subtle button {
  background-color: transparent
}

/* stylelint-disable-next-line no-duplicate-selectors */
.dark .xpl-gh-dialog-container .xpl-button a:disabled,
.dark .xpl-gh-dialog-container .xpl-button a:disabled:hover,
.dark .xpl-gh-dialog-container .xpl-button button:disabled,
.dark .xpl-gh-dialog-container .xpl-button button:disabled:hover {
  background-color: var(--xpl-color-action-disabled-dm);
  border-color: transparent;
  color: var(--xpl-color-text-secondary-dm);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.xpl-gh-dialog-container .xpl-button--link-disabled a,
.xpl-gh-dialog-container .xpl-button--link-disabled a:hover,
.xpl-gh-dialog-container .xpl-button--link-disabled a:focus,
.xpl-gh-dialog-container .xpl-button--link-disabled a:active,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:hover,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:focus,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:active,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:hover,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:focus,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:active,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:hover,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:focus,
.xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:active {
  background-color: var(--xpl-color-action-disabled-lm);
  border-color: var(--xpl-color-action-disabled-lm);
  color: var(--xpl-color-text-secondary-lm);
  cursor: not-allowed;
  border-width: 0px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dark .xpl-gh-dialog-container .xpl-button--link-disabled a,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled a:hover,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled a:focus,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled a:active,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:hover,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:focus,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--success a:active,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:hover,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:focus,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--warning a:active,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:hover,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:focus,
.dark .xpl-gh-dialog-container .xpl-button--link-disabled.xpl-button--neutral a:active {
  background-color: var(--xpl-color-action-disabled-dm);
  border-color: transparent;
  color: var(--xpl-color-text-secondary-dm);
  cursor: not-allowed;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.xpl-gh-dialog-container .xpl-button--full-width a,
.xpl-gh-dialog-container .xpl-button--full-width button {
  width: 100%;
}

.xpl-gh-dialog-container .xpl-button-row {
  display: block;
  width: 100%;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(225 226 232 / var(--tw-border-opacity));
  padding-top: 1.25rem
}

.dark .xpl-gh-dialog-container .xpl-button-row {
  --tw-border-opacity: 1;
  border-color: rgb(106 109 125 / var(--tw-border-opacity))
}

.xpl-gh-dialog-container .xpl-button-row-inner {
  display: flex;
  justify-content: space-between
}

.xpl-gh-dialog-container .xpl-button-row xpl-button+xpl-button .xpl-button {
  margin-left: 1rem
}

.xpl-gh-dialog-container .xpl-button-row__right .xpl-button--secondary {
  display: none;
}

.xpl-gh-dialog-container .xpl-button-row__right {
  display: flex
}

@media screen and (max-width: 640px) {
  .xpl-gh-dialog-container .xpl-button-row-inner {
    display: block
  }

  .xpl-gh-dialog-container .xpl-button-row .xpl-button a,
  .xpl-gh-dialog-container .xpl-button-row .xpl-button button {
    margin-bottom: 1rem;
    width: 100%;
    justify-content: center
  }

  .xpl-gh-dialog-container .xpl-button-row xpl-button+xpl-button .xpl-button {
    margin-left: 0px
  }

  .xpl-gh-dialog-container .xpl-button-row__left .xpl-button--secondary {
    display: none;
  }

  .xpl-gh-dialog-container .xpl-button-row__right .xpl-button--secondary {
    display: block;
  }

  /* so that tertiary appears below primary */
  .xpl-gh-dialog-container .xpl-button-row__right {
    flex-direction: column-reverse
  }

  /* target the first because we're reversing it */
  .xpl-gh-dialog-container .xpl-button-row__right xpl-button:first-child .xpl-button {
    margin-bottom: 0px
  }
}

.xpl-gh-dialog-container .xpl-input {
  position: relative;
  display: block
}

.xpl-gh-dialog-container .xpl-input input {
  height: 2.75rem;
  display: block;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.xpl-gh-dialog-container .xpl-input-calendar {
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
}

button[class^="xpl-input-password"] {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.xpl-gh-dialog-container .xpl-input-calendar path,
[class^="xpl-input-password"] path {
  fill: var(--xpl-color-text-primary-lm);
}

.xpl-gh-dialog-container .xpl-input-error path {
  fill: var(--xpl-color-text-warning-lm);
}

.dark .xpl-gh-dialog-container .xpl-input-calendar path,
.dark [class^="xpl-input-password"] path {
  fill: #fff;
}

.xpl-gh-dialog-container .xpl-input textarea {
  min-height: 44px;
  height: 98px;
  display: block;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dark .xpl-gh-dialog-container .xpl-input input,
.dark .xpl-gh-dialog-container .xpl-input textarea {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-input input:-moz-read-only,
.xpl-gh-dialog-container .xpl-input textarea:-moz-read-only {
  --tw-border-opacity: 1;
  border-color: rgb(248 249 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-input input:read-only,
.xpl-gh-dialog-container .xpl-input textarea:read-only {
  --tw-border-opacity: 1;
  border-color: rgb(248 249 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity))
}

.xpl-gh-dialog-container .xpl-input input:read-only:not(:disabled)::-moz-placeholder,
.xpl-gh-dialog-container .xpl-input textarea:read-only:not(:disabled)::-moz-placeholder {
  color: var(--xpl-color-text-primary-lm);
}

.xpl-gh-dialog-container .xpl-input input:-moz-read-only:not(:disabled)::placeholder,
.xpl-gh-dialog-container .xpl-input textarea:-moz-read-only:not(:disabled)::placeholder {
  color: var(--xpl-color-text-primary-lm);
}

.xpl-gh-dialog-container .xpl-input input:read-only:not(:disabled)::placeholder,
.xpl-gh-dialog-container .xpl-input textarea:read-only:not(:disabled)::placeholder {
  color: var(--xpl-color-text-primary-lm);
}

.xpl-gh-dialog-container .xpl-input input:disabled,
.xpl-gh-dialog-container .xpl-input textarea:disabled {
  cursor: not-allowed;
  background-color: transparent
}

.xpl-gh-dialog-container .xpl-input-label {
  display: block;
  cursor: pointer;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.dark .xpl-gh-dialog-container .xpl-input-label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-input-label small {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(106 109 125 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input-label small {
  --tw-text-opacity: 1;
  color: rgb(153 155 168 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-input--disabled .xpl-gh-dialog-container .xpl-input-label {
  cursor: not-allowed
}

.xpl-gh-dialog-container .xpl-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(225 226 232 / var(--tw-border-opacity));
  padding-left: 0.75rem
}

.xpl-gh-dialog-container .xpl-input-wrapper:focus-within {
  border-color: var(--xpl-color-secondary-lm);
}

.xpl-gh-dialog-container .xpl-input--disabled .xpl-gh-dialog-container .xpl-input-wrapper {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input-wrapper {
  --tw-border-opacity: 1;
  border-color: rgb(106 109 125 / var(--tw-border-opacity))
}

.xpl-gh-dialog-container .xpl-input--error .xpl-gh-dialog-container .xpl-input-wrapper {
  --tw-border-opacity: 1;
  border-color: rgb(219 50 31 / var(--tw-border-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input--disabled .xpl-gh-dialog-container .xpl-input-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(54 50 64 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input--error .xpl-gh-dialog-container .xpl-input-wrapper {
  --tw-border-opacity: 1;
  border-color: rgb(255 147 135 / var(--tw-border-opacity))
}

.xpl-gh-dialog-container .xpl-input-wrapper label {
  color: var(--xpl-color-text-secondary-lm);
  top: 9px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.dark .xpl-gh-dialog-container .xpl-input-wrapper label {
  color: var(--xpl-color-text-secondary-dm);
}

.xpl-gh-dialog-container .xpl-input-wrapper label.xpl-gh-dialog-container .xpl-input-pre {
  flex-shrink: 0;
  padding-left: 0px
}

.xpl-gh-dialog-container .xpl-input-wrapper label.xpl-gh-dialog-container .xpl-input-post {
  border-left-width: 1px;
  line-height: 2.75rem;
  left: auto;
  right: 0.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  --tw-border-opacity: 1;
  border-color: rgb(225 226 232 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.dark .xpl-gh-dialog-container .xpl-input-wrapper label.xpl-gh-dialog-container .xpl-input-post {
  --tw-border-opacity: 1;
  border-color: rgb(106 109 125 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(60 56 71 / var(--tw-bg-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input-wrapper:focus-within {
  border-color: var(--xpl-color-secondary-dm);
}

.xpl-gh-dialog-container .xpl-input-characters {
  background-color: #fffd;
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(106 109 125 / var(--tw-text-opacity));
}

.dark .xpl-gh-dialog-container .xpl-input-characters {
  background-color: #fff4;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.xpl-gh-dialog-container .xpl-input--disabled .xpl-gh-dialog-container .xpl-input-characters {
  background-color: transparent
}

.xpl-gh-dialog-container .xpl-input--error .xpl-gh-dialog-container .xpl-input-characters {
  --tw-text-opacity: 1;
  color: rgb(194 33 15 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input--error .xpl-gh-dialog-container .xpl-input-characters {
  --tw-text-opacity: 1;
  color: rgb(255 147 135 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-input-error {
  display: block;
  padding-top: 0.25rem;
  text-align: right;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(194 33 15 / var(--tw-text-opacity))
}

.dark .xpl-gh-dialog-container .xpl-input-error {
  --tw-text-opacity: 1;
  color: rgb(255 147 135 / var(--tw-text-opacity))
}

.xpl-gh-dialog-container .xpl-input-error svg {
  top: 1px;
  position: relative;
  margin-right: 0.5rem;
}

.dark .xpl-gh-dialog-container .xpl-input-error path {
  fill: var(--xpl-color-text-warning-dm);
}

.xpl-gh-dialog-container .xpl-label {
  display: block;
  width: 100%;
  cursor: pointer;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.xpl-gh-dialog-container .xpl-label--disabled {
  cursor: not-allowed !important;
  --tw-text-opacity: 1;
  color: rgb(106 109 125 / var(--tw-text-opacity));
}

.is--fixed {
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}
