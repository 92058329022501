const isDevEnv = process.env.NODE_ENV === 'development';

export default function generateTriggerEvent<T = unknown>() {
  return <U = unknown>(eventName: T, opts: CustomEventInit<U> = {}) => {
    const evt = new CustomEvent(eventName as string, opts);
    document.dispatchEvent(evt);

    // eslint-disable-next-line no-console
    if (isDevEnv) console.log(eventName, opts);
  };
}
