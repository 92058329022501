import GrowthHubElement from './controllers/growth-hub';

export default (function () {
  const generateXplGrowthHub = () => {
    let isActive = false;
    let currentUser = {
      currentAccount: {
        id: null as unknown as number,
        name: '',
      },
      email: '',
      fullName: '',
      isSuper: false,
      userHash: '',
      uuid: '',
    };

    return {
      get currentUser() {
        return currentUser;
      },
      set currentUser(updatedCurrentUser) {
        currentUser = updatedCurrentUser;
      },
      get isActive() {
        return isActive;
      },
      setIsActive() {
        isActive = true;
        return isActive;
      },
      init() {
        const styleElementId = 'data-growth-hub-client-css';
        const styleElement = document.createElement('style');

        styleElement.setAttribute(styleElementId, 'true');
        styleElement.textContent = `growth-hub:not(:defined) > * { display: none; }`;

        if (!document.querySelector(`[${styleElementId}]`)) {
          document.head.appendChild(styleElement);
        }

        const growthHubElmName = 'growth-hub';

        if (!customElements.get(growthHubElmName)) {
          customElements.define(growthHubElmName, GrowthHubElement);
        }
      },
    };
  };

  window.XplGrowthHub = generateXplGrowthHub();
})();
