import { CurrentUser, OmitFirst } from '@client/types';

export class IntercomService {
  appId: string;

  isDevEnv = process.env.NODE_ENV === 'development';

  constructor(intercomAppId: string) {
    this.appId = intercomAppId;
  }

  configure() {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      // @ts-expect-error: we don't need to type Intercom
      i.q = [];
      // @ts-expect-error: we don't need to type Intercom
      i.c = function (args) {
        i.q.push(args);
      };
      // @ts-expect-error: we don't need to type Intercom
      w.Intercom = i;
      const l = function (appId: string) {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        document.body.appendChild(s);
      };
      l(this.appId);
    }
  }

  init(user: CurrentUser) {
    if (this.appId) {
      if (!user?.isSuper) {
        window.intercomSettings = {
          app_id: this.appId,
          email: user?.email,
          name: user?.fullName,
          user_hash: user?.userHash,
          user_id: user?.uuid,
        };
      } else {
        window.intercomSettings = {
          app_id: this.appId,
        };
      }

      this.configure();
      window.Intercom('boot', window.intercomSettings);
    }
  }

  Intercom(
    command: Parameters<typeof window.Intercom>[0],
    ...params: OmitFirst<Parameters<typeof window.Intercom>>
  ) {
    if (this.isDevEnv) {
      // eslint-disable-next-line no-console
      console.log(command, ...params);
    }
    window.Intercom(command, ...params);
  }
}
