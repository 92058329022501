:host {
  display: flex;
  flex-wrap: wrap;
  font-family: apple-system, system-ui, "Segoe UI", Arial, Helvetica, Roboto, sans-serif;
  --xpl-size-spacing-144: 8.75rem;
  --xpl-size-spacing-112: 7rem;
  --xpl-size-spacing-80: 5rem;
  --xpl-size-spacing-64: 4rem;
  --xpl-size-spacing-48: 3rem;
  --xpl-size-spacing-32: 2rem;
  --xpl-size-spacing-24: 1.5rem;
  --xpl-size-spacing-16: 1rem;
  --xpl-size-spacing-12: 0.75rem;
  --xpl-size-spacing-8: 0.5rem;
  --xpl-size-spacing-4: 0.25rem;
  --xpl-size-spacing-0: 0rem;
  --xpl-size-font-caption: 0.75rem;
  --xpl-size-font-callout: 0.75rem;
  --xpl-size-font-body: 0.875rem;
  --xpl-size-font-title-5: 0.875rem;
  --xpl-size-font-title-4: 1rem;
  --xpl-size-font-title-3: 1.25rem;
  --xpl-size-font-title-2: 1.5rem;
  --xpl-size-font-title-1: 1.875rem;
  --xpl-font-weight-normal: normal;
  --xpl-font-weight-medium: medium;
  --xpl-font-weight-bold: bold;
  --xpl-font-family-default: apple-system, system-ui, 'Segoe UI', Arial, Helvetica, Roboto, sans-serif;
  --xpl-color-transparent: rgba(255, 255, 255, 0);
  --xpl-color-red-900: #731409;
  --xpl-color-red-800: #961305;
  --xpl-color-red-700: #c2210f;
  --xpl-color-red-600: #db321f;
  --xpl-color-red-500: #f24f3d;
  --xpl-color-red-400: #f87263;
  --xpl-color-red-300: #ff9387;
  --xpl-color-red-200: #fec5be;
  --xpl-color-red-100: #fed9d5;
  --xpl-color-red-50: #fef7f3;
  --xpl-color-pink-900: #730b3f;
  --xpl-color-pink-800: #990f54;
  --xpl-color-pink-700: #bf1d7b;
  --xpl-color-pink-600: #cc3399;
  --xpl-color-pink-500: #e550c8;
  --xpl-color-pink-400: #f26ddc;
  --xpl-color-pink-300: #ff8df4;
  --xpl-color-pink-200: #ffb8f9;
  --xpl-color-pink-100: #ffd9fb;
  --xpl-color-pink-50: #fff0fe;
  --xpl-color-yellow-900: #5c5002;
  --xpl-color-yellow-800: #726916;
  --xpl-color-yellow-700: #8a8220;
  --xpl-color-yellow-600: #9e9828;
  --xpl-color-yellow-500: #adab20;
  --xpl-color-yellow-400: #c6cc4e;
  --xpl-color-yellow-300: #dce967;
  --xpl-color-yellow-200: #ecfd91;
  --xpl-color-yellow-100: #f3febb;
  --xpl-color-yellow-50: #fbffe5;
  --xpl-color-green-900: #154737;
  --xpl-color-green-800: #1d614b;
  --xpl-color-green-700: #24785d;
  --xpl-color-green-600: #2e9977;
  --xpl-color-green-500: #37b88f;
  --xpl-color-green-400: #4ad4a8;
  --xpl-color-green-300: #52ebba;
  --xpl-color-green-200: #74fbd0;
  --xpl-color-green-100: #c4ffed;
  --xpl-color-green-50: #ebfff8;
  --xpl-color-orange-900: #7a2714;
  --xpl-color-orange-800: #a6361a;
  --xpl-color-orange-700: #cc4121;
  --xpl-color-orange-600: #f44e27;
  --xpl-color-orange-500: #f76d43;
  --xpl-color-orange-400: #f99170;
  --xpl-color-orange-300: #ffbda8;
  --xpl-color-orange-200: #ffdccc;
  --xpl-color-orange-100: #feede4;
  --xpl-color-orange-50: #fef6f4;
  --xpl-color-purple-900: #330d80;
  --xpl-color-purple-800: #4d1ab2;
  --xpl-color-purple-700: #6923f4;
  --xpl-color-purple-600: #793ef7;
  --xpl-color-purple-500: #8857fa;
  --xpl-color-purple-400: #a480ff;
  --xpl-color-purple-300: #c3adff;
  --xpl-color-purple-200: #ddd1ff;
  --xpl-color-purple-100: #ebe5ff;
  --xpl-color-purple-50: #f4f2ff;
  --xpl-color-gray-1200: #18161f;
  --xpl-color-gray-1100: #201e29;
  --xpl-color-gray-1000: #292632;
  --xpl-color-gray-900: #302d3b;
  --xpl-color-gray-800: #363240;
  --xpl-color-gray-700: #3c3847;
  --xpl-color-gray-600: #6a6d7d;
  --xpl-color-gray-500: #999ba8;
  --xpl-color-gray-400: #e1e2e8;
  --xpl-color-gray-300: #eaebef;
  --xpl-color-gray-200: #eeeff3;
  --xpl-color-gray-100: #f3f4f6;
  --xpl-color-gray-50: #f8f9fa;
  --xpl-color-gray-0: #ffffff;
  --xpl-color-text-warning-dm: var(--xpl-color-red-300);
  --xpl-color-text-warning-lm: var(--xpl-color-red-700);
  --xpl-color-text-reverse-dm: var(--xpl-color-gray-1000);
  --xpl-color-text-reverse-lm: var(--xpl-color-gray-0);
  --xpl-color-text-secondary-dm: var(--xpl-color-gray-500);
  --xpl-color-text-secondary-lm: var(--xpl-color-gray-600);
  --xpl-color-text-primary-dm: var(--xpl-color-gray-0);
  --xpl-color-text-primary-lm: var(--xpl-color-gray-900);
  --xpl-color-action-warning-hover-dm: var(--xpl-color-red-400);
  --xpl-color-action-warning-hover-lm: var(--xpl-color-red-800);
  --xpl-color-action-warning-dm: var(--xpl-color-red-300);
  --xpl-color-action-warning-lm: var(--xpl-color-red-700);
  --xpl-color-action-disabled-dm: var(--xpl-color-gray-800);
  --xpl-color-action-disabled-lm: var(--xpl-color-gray-100);
  --xpl-color-action-primary-hover-dm: var(--xpl-color-purple-400);
  --xpl-color-action-primary-hover-lm: var(--xpl-color-purple-800);
  --xpl-color-action-primary-dm: var(--xpl-color-purple-300);
  --xpl-color-action-primary-lm: var(--xpl-color-purple-700);
  --xpl-color-trivial-bg-dm: var(--xpl-color-transparent);
  --xpl-color-trivial-bg-lm: var(--xpl-color-gray-400);
  --xpl-color-trivial-dm: var(--xpl-color-gray-500);
  --xpl-color-trivial-lm: var(--xpl-color-gray-600);
  --xpl-color-information-bg-dm: var(--xpl-color-transparent);
  --xpl-color-highlight-bg-dm: var(--xpl-color-transparent);
  --xpl-color-highlight-bg-lm: var(--xpl-color-yellow-200);
  --xpl-color-highlight-dm: var(--xpl-color-yellow-200);
  --xpl-color-highlight-lm: var(--xpl-color-yellow-500);
  --xpl-color-negative-bg-dm: var(--xpl-color-transparent);
  --xpl-color-negative-bg-lm: var(--xpl-color-red-100);
  --xpl-color-negative-dm: var(--xpl-color-red-300);
  --xpl-color-negative-lm: var(--xpl-color-red-600);
  --xpl-color-positive-bg-dm: var(--xpl-color-transparent);
  --xpl-color-positive-bg-lm: var(--xpl-color-green-100);
  --xpl-color-positive-dm: var(--xpl-color-green-300);
  --xpl-color-positive-lm: var(--xpl-color-green-500);
  --xpl-color-secondary-bg-dm: var(--xpl-color-gray-900);
  /* themable */
  --xpl-color-secondary-bg-lm: var(--xpl-color-purple-50);
  /* themable */
  --xpl-color-secondary-hover-dm: var(--xpl-color-purple-400);
  /* themable */
  --xpl-color-secondary-hover-lm: var(--xpl-color-purple-800);
  /* themable */
  --xpl-color-secondary-dm: var(--xpl-color-purple-300);
  /* themable */
  --xpl-color-secondary-lm: var(--xpl-color-purple-700);
  /* themable */
  --xpl-color-primary-bg-dm: var(--xpl-color-gray-900);
  /* themable */
  --xpl-color-primary-bg-lm: var(--xpl-color-orange-50);
  /* themable */
  --xpl-color-primary-dm: var(--xpl-color-orange-400);
  /* themable */
  --xpl-color-primary-lm: var(--xpl-color-orange-600);
  /* themable */
  --xpl-color-stroke-dm: var(--xpl-color-gray-700);
  --xpl-color-stroke-lm: var(--xpl-color-gray-400);
  --xpl-color-navigation-dm: var(--xpl-color-gray-1000);
  --xpl-color-navigation-lm: var(--xpl-color-gray-1000);
  --xpl-color-background-2-dm: var(--xpl-color-gray-1100);
  --xpl-color-background-2-lm: var(--xpl-color-gray-100);
  --xpl-color-background-dm: var(--xpl-color-gray-1200);
  --xpl-color-background-lm: var(--xpl-color-gray-50);
  --xpl-color-foreground-dm: var(--xpl-color-gray-1000);
  --xpl-color-foreground-lm: var(--xpl-color-gray-0);
  --xpl-color-accent-purple: var(--xpl-color-purple-300);
  --xpl-color-accent-pink: var(--xpl-color-pink-300);
  --xpl-color-accent-yellow: var(--xpl-color-yellow-200);
  --xpl-color-accent-green: var(--xpl-color-green-200);
  --xpl-color-text-action-dm: var(--xpl-color-primary-dm);
  /* uses themable token */
  --xpl-color-text-action-lm: var(--xpl-color-primary-lm);
  /* uses themable token */
  --xpl-color-information-bg-lm: var(--xpl-color-primary-bg-lm);
  /* uses themable token */
  --xpl-color-information-dm: var(--xpl-color-primary-dm);
  /* uses themable token */
  --xpl-color-information-lm: var(--xpl-color-primary-lm);
  /* uses themable token */
}

:host * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:host .xpl-gh-text-title-1 {
  font-size: var(--xpl-size-font-title-1);
  font-weight: bold;
  line-height: 1.2;
}

:host .xpl-gh-text-title-2 {
  font-size: var(--xpl-size-font-title-2);
  font-weight: bold;
  line-height: 1.33333333;
}

:host .xpl-gh-text-title-3 {
  font-size: var(--xpl-size-font-title-3);
  font-weight: bold;
  line-height: 1.4;
}

:host .xpl-gh-text-title-4 {
  font-size: var(--xpl-size-font-title-4);
  font-weight: 500;
  line-height: 1.5;
}

:host .xpl-gh-text-title-5 {
  font-size: var(--xpl-size-font-title-5);
  font-weight: 500;
  line-height: 1.42857143;
}

:host .gh-container {
  height: 0;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: 0;
}

:host figure {
  margin: 0;
  padding: 0;
}

:host #main-logo {
  margin-right: 1.5rem;
}

:host header {
  align-items: center;
  background: #292632;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  width: 100%;
}

:host .is--fixed {
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

:host header.is--fixed.dialog--is-active {
  z-index: 10000;
}

:host nav#main-nav {
  display: flex;
  align-items: center;
}

:host nav#main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:host nav#main-nav > ul {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

:host nav:last-of-type xpl-select {
  display: block;
  min-width: 11.875rem;
  width: 100%;
}

:host #account-details {
  align-items: center;
  display: flex;
  position: relative;
  height: 2.75rem;
}

:host .user-avatar button {
  max-width: 2.5rem;
}

:host .xpl-select-loader {
  height: 100%;
  width: 11.875rem;
  position: relative;
}

:host ul li {
  position: relative;
}

:host ul li > ul {
  background: #302D3B;
  border-radius: 0.25rem;
  border: 1px solid #6A6D7D;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  min-width: 15.5rem;
  z-index: 1;
}

:host ul li:hover > ul {
  opacity: 1;
  pointer-events: initial;
}

:host nav#main-nav li button {
  padding: .75rem;
}

:host a,
:host button {
  font-size: 0.875rem;
}

:host a {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

:host a,
:host a:link,
:host a:visited {
  color: var(--xpl-color-text-secondary-dm);
}

:host .dd-arrow {
  display: inline-block;
  margin-left: .25rem;
}

:host a:hover {
  color: white;
}

:host a:hover button {
  background: var(--xpl-color-gray-700);
}

:host button:hover .dd-arrow {
  animation: bounce 2s ease infinite;
}

:host svg {
  fill: #fff;
  font-weight: normal;
}

:host a.active {
  color: white;
}

:host .xpl-input-wrapper {
  border: none;
}

:host .xpl-select-value {
  color: var(--xpl-color-text-secondary-dm);
}

:host .main-content__container {
  flex: 1;
  position: relative;
}

:host nav#main-nav button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  padding: 0.5rem;
  text-align: left;
  width: 100%;
}

:host svg {
  vertical-align: middle;
}

:host * iframe {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity .2s ease-in-out;
}

:host .dropdown-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 10rem;
}

:host .dropdown-container .xpl-dropdown-list {
  width: 100%;
}

:host .xpl-dropdown-option {
  font-weight: 500;
}

:host .xpl-dropdown-option a,
:host .xpl-dropdown-option a:active,
:host .xpl-dropdown-option a:visited {
  color: white;
}

:host .xpl-dropdown-option a {
  text-decoration: none;
}

:host #xpl-gh-current-account-display {
  color: #999BA8;
  padding: 1rem;
}

:host .load-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #6a6d7d;
  overflow: hidden;
  border-radius: 5px;
}

:host .activity {
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(to left, rgb(60 56 71 / 5%), rgb(60 56 71 / 30%), rgb(60 56 71 / 60%), rgb(60 56 71 / 30%), rgb(60 56 71 / 5%));
  animation: loading 1s infinite;
  z-index: 1;
}

:host .overflow-hidden {
  overflow: hidden;
}

@keyframes loading {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-7%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
  }

}
