import { ServiceLinkItem } from '~/types';
import { getActiveService } from '../utils/get-active-service';

export class ActiveStateService {
  navItemActiveClass: string;

  constructor(navItemActiveClass: typeof this.navItemActiveClass) {
    this.navItemActiveClass = navItemActiveClass;
  }

  getClickedService(evt: MouseEvent, services: ServiceLinkItem[]) {
    type ReturnTuple = [HTMLAnchorElement | null, ServiceLinkItem | null];

    if (!evt) throw new Error('no MouseEvent passed to method');
    if (!services) throw new Error('no Services array passed to method');

    const evtTargetArr = evt.composedPath();

    const linkElm = evtTargetArr.find((elm) => {
      const typedElm = elm as HTMLElement;
      const dataAttrs = Object.keys(typedElm?.dataset || {});

      return (
        (typedElm?.tagName || '').toLowerCase() === 'a' &&
        dataAttrs.includes('growthHubLink')
      );
    }) as HTMLAnchorElement;

    const linkElmHref = linkElm?.getAttribute('href');

    if (linkElm && linkElmHref && linkElmHref !== 'javascript:;') {
      const activeService = getActiveService(services || [], linkElmHref);

      return [linkElm, activeService] as ReturnTuple;
    }

    return [null, null] as ReturnTuple;
  }

  setActiveNavItems(
    linkElm: HTMLAnchorElement,
    activeService: ServiceLinkItem,
  ) {
    const mainNavElm = linkElm?.closest('#main-nav');

    Array.from(
      mainNavElm?.querySelectorAll('a[data-growth-hub-link]') || [],
    ).forEach((elm) => {
      const classListAction: 'add' | 'remove' =
        elm?.getAttribute('href') === activeService?.url ? 'add' : 'remove';

      elm.classList[classListAction](this.navItemActiveClass);
    });

    return mainNavElm;
  }

  setActiveService(services: ServiceLinkItem[], activeServiceUrl: string) {
    return services.map((service) => ({
      ...service,
      isActive: service.url === activeServiceUrl,
    }));
  }
}
