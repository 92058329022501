import { TemplateVars } from '@server/types';

export default function parsePostMsg(msgData: string) {
  let parsedData;

  if (
    typeof msgData === 'string' &&
    msgData.includes('{') &&
    msgData.includes('}')
  ) {
    parsedData = JSON.parse(msgData) as TemplateVars['iframeMsg'];
  } else {
    parsedData = msgData;
  }

  return parsedData as TemplateVars['iframeMsg'];
}
