import { NavLinksWActive } from '@client/types';
import createListItemReducer from './create-list-item-reducer';
import createLink from './create-link';

export default function createMenuItem({
  category,
  isActive,
  services,
}: NavLinksWActive) {
  const serviceList = services.reduce(createListItemReducer, []);

  if (serviceList.length === 1) return serviceList;

  const unorderedList = document.createElement('ul');

  serviceList.forEach((serviceListItem) => {
    unorderedList.appendChild(serviceListItem);
  });

  const listItem = document.createElement('li');
  listItem.appendChild(
    createLink({
      isExternalService: false,
      hasChildren: true,
      isActive,
      text: category,
      url: 'javascript:;',
    }),
  );

  listItem.appendChild(unorderedList);

  return [listItem];
}
